import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["overlay"];
  connect() {
   
  }

  toggleOverlay(e) {
    this.overlayTargets.forEach((overlay) => {
      overlay.classList.add("d-none");
      let preSelected = overlay.closest(".pointer-events-none");
      if(preSelected) preSelected.classList.toggle("pointer-events-none");
    });
    let selectedOverlay = e.currentTarget.parentNode.querySelector(".selected-overlay");
    selectedOverlay.classList.toggle("d-none");
    e.currentTarget.closest(".sel-products").classList.toggle("pointer-events-none");
  }
}