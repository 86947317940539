import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template", "levels" ]

  connect () {
    this.currencySymbol = this.element.dataset["currencySymbol"];
  }

  addLevel(e) {
    console.log("click");
    let new_index = new Date().getTime();
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new_index);
    this.levelsTarget.insertAdjacentHTML('beforeend', content);
    let level = this.levelsTarget.querySelector("#level_" + new_index);
    $('[data-toggle="tooltip"]', level).tooltip();
  }

  removeLevel(e) {
    //TODO Do a confirmation here
    e.stopPropagation();
    e.preventDefault();
    $(e.currentTarget).tooltip("hide");
    e.target.closest("tr").remove();
  }

  setDonationMessage(e) {
    console.log("set message");
    let parent = $(e.currentTarget).parents("tr").first();
    let cartVal = $(".cart-value", parent).val();
    let donationVal = $(".donation-value", parent).val();
    let donationType = $(".donation-type", parent).val();
    console.log(donationType);
    let formattedDonationVal = (donationType == 'percentage' ? `${donationVal}%` : `${this.currencySymbol}${donationVal}`);
    let donationMessage = `When you spend ${this.currencySymbol}${cartVal} or more donate ${formattedDonationVal} of your order to a cause with impact.`;
    $('.donation-message-text', parent).text(donationMessage);
    $('.donation-message', parent).val(donationMessage);
  }
}