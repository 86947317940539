import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["container", "campaigns", "counter"];
  counter = parseInt(this.counterTarget.dataset["count"]);

  connect() {
   
  }

  // create method that clicks on modal

  toggleOverlay(e) {    
    let className;
    if (e.currentTarget.dataset["campaign"] === "store") className = ".store-giving"; 
    if (e.currentTarget.dataset["campaign"] === "customer") className = ".customer-giving"; 
    if (e.currentTarget.dataset["campaign"] === "cart") className = ".upsell-giving"; 

    let campaignCard = this.campaignsTarget.querySelector(className);

    // query select the correct div
    let onbordingCard = campaignCard.querySelector(".onboarding-card");
    // let onbordingCard = e.currentTarget.parentNode.parentNode;
    // let campaignCard = e.currentTarget.parentNode.parentNode.parentNode.parentNode;
    let selectedOverlay = onbordingCard.querySelector(".selected-overlay");
    
    let campaign = onbordingCard.dataset["campaign"];
    
    campaignCard.classList.toggle("pointer-events-none");
    campaignCard.classList.toggle("cursor-pointer")
    selectedOverlay.classList.toggle("d-none");
    this.toggleChecked(campaign);
    this.setCampaign(campaign);
    selectedOverlay.classList.contains("d-none") ? this.updateCounter("-") : this.updateCounter("+")
  }

  toggleChecked(campaign) {
    let storeGivingChekced = this.containerTarget.querySelector(".store-giving-checked");
    let customerGivingChecked = this.containerTarget.querySelector(".customer-giving-checked");
    let upsellGivingChecked = this.containerTarget.querySelector(".upsell-giving-checked");
    
    if (campaign === "store") storeGivingChekced.classList.toggle("d-none");
    if (campaign === "customer") customerGivingChecked.classList.toggle("d-none");
    if (campaign === "cart") upsellGivingChecked.classList.toggle("d-none");
  }

  uncheckCampaign(e) {
    let currentCampaign = e.currentTarget.dataset["campaign"];

    this.setCampaign(currentCampaign);
    // if (currentCampaign === "store") $(".store-checkbox").trigger('click');
    // if (currentCampaign === "customer") $(".customer-checkbox").trigger('click');
    // if (currentCampaign === "cart") $(".cart-checkbox").trigger('click');
  }

  updateCounter(operator) {
    if (operator === "+") this.counter += 1 
    if (operator === "-") this.counter -= 1 

    this.counterTarget.innerHTML = `Ways to give (${this.counter}/3):`
  }

  modalButton(e) {
    this.setCampaign(e.currentTarget.dataset["campaign"]);
  }

  // checkParent(e) {
  //   // $(e.currentTarget).parents(".selector-card").toggleClass("active")
  //   let onbordingCard = e.currentTarget.parentNode.parentNode;
  //   let selectedOverlay = onbordingCard.querySelector(".selected-overlay");

  //   onbordingCard.classList.toggle("pointer-events-none");
  //   onbordingCard.classList.toggle("cursor-pointer");
  //   selectedOverlay.classList.toggle("d-none");
  // }

  // toggle(e) {
  //   $(e.currentTarget).siblings("input").first().trigger('click')
  // }

  setCampaign(currentCampaign) {
    if (currentCampaign === "store") $(".store-checkbox").trigger('click');
    if (currentCampaign === "customer") $(".customer-checkbox").trigger('click');
    if (currentCampaign === "cart") $(".cart-checkbox").trigger('click');
  }
}
