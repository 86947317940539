import { Controller } from "stimulus";

// This controller should only apply for the demo to Cat's Protection on 09/10/24
export default class extends Controller {
  static targets = [
    "perOrderFields",
    "recurringFields",
    "otherFields",
    "pledgeTypeInput",
  ];
  static values = {
    pledgeType: Array,
  };

  connect() {
    this.updateFormVisibility();
  }

  pledgeTypeChanged(event) {
    const newValues = {
      per_order: this.pledgeTypeValue.includes("per_order"),
      recurring: this.pledgeTypeValue.includes("recurring"),
      other: this.pledgeTypeValue.includes("other"),
    };
    const pledgeType = event.currentTarget.value;
    const value = event.currentTarget.checked;
    newValues[pledgeType] = value;

    const newPledgeTypeValue = [];
    for (const [k, v] of Object.entries(newValues)) {
      if (v) newPledgeTypeValue.push(k);
    }
    this.pledgeTypeValue = newPledgeTypeValue;

    this.updateFormVisibility();
  }

  updateFormVisibility() {
    if (this.pledgeTypeValue.includes("per_order")) {
      const clone = this.perOrderFieldsTarget.content.cloneNode(true);
      const input = this.pledgeTypeInputTargets.find(
        (target) => target.value === "per_order"
      ).parentNode;
      if (!this.isTemplateContent(input.nextSibling)) {
        input.insertAdjacentElement("afterend", clone.firstElementChild);
      }
    } else {
      const input = this.pledgeTypeInputTargets.find(
        (target) => target.value === "per_order"
      ).parentNode;
      const sibling = input.nextSibling;
      if (this.isTemplateContent(sibling)) sibling.remove();
    }

    if (this.pledgeTypeValue.includes("recurring")) {
      const clone = this.recurringFieldsTarget.content.cloneNode(true);
      const input = this.pledgeTypeInputTargets.find(
        (target) => target.value === "recurring"
      ).parentNode;
      if (!this.isTemplateContent(input.nextSibling)) {
        input.insertAdjacentElement("afterend", clone.firstElementChild);
      }
    } else {
      const input = this.pledgeTypeInputTargets.find(
        (target) => target.value === "recurring"
      ).parentNode;
      const sibling = input.nextSibling;
      if (this.isTemplateContent(sibling)) sibling.remove();
    }

    if (this.pledgeTypeValue.includes("other")) {
      const clone = this.otherFieldsTarget.content.cloneNode(true);
      const input = this.pledgeTypeInputTargets.find(
        (target) => target.value === "other"
      ).parentNode;
      if (!this.isTemplateContent(input.nextSibling)) {
        input.insertAdjacentElement("afterend", clone.firstElementChild);
      }
    } else {
      const input = this.pledgeTypeInputTargets.find(
        (target) => target.value === "other"
      )?.parentNode;
      const sibling = input?.nextSibling;
      if (sibling && this.isTemplateContent(sibling)) sibling.remove();
    }
  }

  isTemplateContent(el) {
    return el.nodeName === "DIV";
  }
}
