import { Controller } from "stimulus"
import { titleize } from '../utils/helpers'


export default class extends Controller {
    static targets = [ 'button', 'modal', 'field', 'modalBody' ]

    connect() {
      this.defaultButtonText = this.buttonTarget.textContent;
    }

    onPostSuccess(event) {
        let [data, status, xhr] = event.detail;
        // Clear the errors
        this.element.querySelectorAll("input.is-invalid").forEach((el) => {
            el.classList.remove("is-invalid");
            el.parentNode.removeChild(el.parentNode.querySelector("div.invalid-feedback"))
        });
        //Clear changed fields
        this.element.querySelectorAll(".reset").forEach((el) => {
            el.value = "";
        });
        //Show the success message
        let successElement = document.createElement("div")
        successElement.setAttribute("class", "alert alert-success")
        successElement.innerHTML = data.message
        this.modalBodyTarget.append(successElement)
    }

    onPostError(event) {
        let [data, status, xhr] = event.detail;
        console.log(event);
        console.log(data.errors);
        var model_name = this.element.dataset.model;
        for(var error in data.errors) {
            var el = this.fieldTargets.find(e => e.id == model_name + "_" + error)
            // el = this.fieldTargets[]
            if(el) {
                let errorElement = el.closest(".form-group").querySelector("div.invalid-feedback")
                if(errorElement == undefined) {
                    errorElement = document.createElement("div")
                    errorElement.setAttribute("class", "invalid-feedback")
                    el.parentNode.insertBefore(errorElement, el.nextSibling)
                }
                el.classList.add("is-invalid")
                errorElement.textContent = titleize(data.errors[error]);
            }
            // console.log(titleize(error) + ' ' + data.errors[error]);
        }

        // alert(data.errors[0][0]);
    }

    displayProcessing() {
        this.buttonTarget.setAttribute("disabled", "true")
        var loader = document.createElement("span")
        loader.classList.add("spinner-border", "spinner-border-sm")
        console.log(loader)
        this.buttonTarget.innerHTML = loader.outerHTML;
    }
    stopProcessing() {
        this.buttonTarget.removeAttribute("disabled");
        this.buttonTarget.textContent = this.defaultButtonText;
    }
}