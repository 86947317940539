import { Controller } from "stimulus"
import {titleize} from "../utils/helpers"
import moment from 'moment';

export default class extends Controller {
  static targets = [ "template", "container", "empty" ];

  connect() {
    this.empty = document.getElementById("giving-items-empty");
  }

  addItem(e) {
    let data = JSON.parse(e.currentTarget.dataset.externalPayload);
    let new_index = new Date().getTime();
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new_index);
    if(this.empty) this.empty.remove();
    this.containerTarget.insertAdjacentHTML('beforeend', content);
    let givingItem = this.containerTarget.querySelector("#giving_item_" + new_index);
    givingItem.querySelector(".giving-item-external-id").value = data.id;
    givingItem.querySelector("td.giving-item-name").textContent = `${data.name}${data.products_count != undefined ? ` (${data.products_count} products)` : ''}`;
    givingItem.querySelector("input.giving-item-name").value = data.name;
    givingItem.querySelector(".giving-item-image").src = data.image;
    givingItem.querySelector(".giving-item-external-payload").value = e.currentTarget.dataset.externalPayload;
    givingItem.querySelector(".giving-item-type").value = this.givingItemsSearchController.givingItemType;
    givingItem.querySelector(".item-type-badge").textContent = titleize(this.givingItemsSearchController.givingItemType);
  }

  removeItem(e) {
    e.preventDefault();
    let tr = e.currentTarget.closest("tr")
    if(confirm("You are about to remove this item.")) {
      tr.querySelector(".giving-item-deleted-at").value = moment().format();
      tr.classList.add("d-none");
    }
  }

  selectDonationType(e) {
    let container = e.currentTarget.closest(".input-group");
    container.querySelectorAll("label.btn").forEach((btn) => {
      btn.classList.remove("btn-secondary");
      btn.classList.add("btn-default");
    });
    let label = e.currentTarget.closest("label.btn")
    label.classList.add("btn-secondary");
    label.classList.remove("btn-default");
  }

  changeSelectCause(e) {
    if(e.currentTarget.value == "all_causes") {
      this.containerTarget.querySelectorAll(".giving-item-search-cause").forEach((s) => {
        s.classList.add("d-none");
      });
      this.containerTarget.querySelectorAll(".all-causes-same").forEach((s) => {
        s.classList.remove("d-none");
      });
    } else {
      this.containerTarget.querySelectorAll(".giving-item-search-cause").forEach((s) => {
        s.classList.remove("d-none");
      });
      this.containerTarget.querySelectorAll(".all-causes-same").forEach((s) => {
        s.classList.add("d-none");
      });
    }
  }

  get givingItemsSearchController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "giving-items-search");
  }
}