import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "checkboxAll" ]

  connect () {
  }

  toggle(e) {
    e.preventDefault()
    this.checkboxTargets.forEach(checkbox => {
      // @ts-ignore
      checkbox.checked = e.target.checked
    });
  }

  individualToggle(e) {
    console.log("individual");
    if(this.checkboxAllTarget.checked) {
      this.checkboxAllTarget.checked = false
    }
  }
}