import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "modal"]
  connect() {
  }
  closePopup(e) {
    let data = {  showLearnMorePopup: false }
    parent.postMessage(JSON.stringify(data), this.element.dataset.location );
    e.preventDefault();
  }
  openPopup(e) {
    e.preventDefault();
    let data = {  showLearnMorePopup: true };
    parent.postMessage(JSON.stringify(data), this.element.dataset.location );
  }
}