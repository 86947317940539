import { Controller } from "stimulus";
import { I18n } from "i18n-js";
import { TRANSLATIONS } from "../locales/translations.js";

let previousVariantId;
let selectedQuantity;
let causeIsSelected = false;
export default class extends Controller {
  static targets = [
    "modal",
    "button",
    "chosenCause",
    "causeList",
    "causeSelection",
    "confirmation",
    "changeCause",
    "main",
    "customerDonationsWidget",
    "donationOption",
    "customDisplay",
    "customInput"
  ];

  initialize() {
    this.saveCauseMetaData(this.chosenCauseTarget.dataset["metadata"]);
    if (this.causeSelectionTarget.dataset["chosenCause"] == "true") {
      causeIsSelected = true;
      if(this.element.dataset["chosenCauseVariantId"]) previousVariantId = this.element.dataset["chosenCauseVariantId"];
      selectedQuantity = this.causeSelectionTarget.dataset["quantity"];      
    }
    this.roundUp = this.element.dataset["roundUp"] == "1";
    this.fixedWholeAmt = this.element.dataset["fixedWholeAmt"] == "1";
    this.variantType = this.element.dataset["variantType"];
    this.customVariantType = this.element.dataset["customVariantType"];
    this.currencyRate = parseFloat(this.element.dataset["currencyRate"]);
    this.productValue = parseFloat(this.element.dataset["productValue"]);
    this.wholeProductValue = parseFloat(this.element.dataset["wholeProductValue"]);
    this.variantIds = [];
    this.centsVariantIds = [];
    if(this.causeListTargets.length > 0) {
      this.causeListTargets.forEach((cause) => {
        this.variantIds.push(cause.dataset["variantId"]);
        this.centsVariantIds.push(cause.dataset["centsVariantId"]);
      });
    } else {
      this.variantIds.push(this.chosenCauseTarget.dataset["variantId"]);
      this.centsVariantIds.push(this.chosenCauseTarget.dataset["centsVariantId"]);
    }
  }

  addCustomAmount(e) {
    if(this.customInputTarget.value && parseInt(this.customInputTarget.value) > 0) {
      selectedQuantity = parseInt(this.customInputTarget.value) / (this.customVariantType == "whole" ? this.wholeProductValue : this.productValue);
      this.selectCause(e);
    }
  }

  showCustom(e) {
    this.causeSelectionTarget.classList.add("d-none");
    this.customDisplayTarget.classList.remove("d-none");
    this.customInputTarget?.focus();
  }

  connect() {
  parent.postMessage(JSON.stringify({checkChosenCauseOnLoad: true}), this.element.dataset.location );
    if(this.mainTarget?.dataset["donationOptions"]?.length)
      this.donationOptions = JSON.parse(this.mainTarget?.dataset["donationOptions"]);
  }
  
  show() {
    this.modalTarget.classList.remove("d-none");
  }

  dismiss() {
    this.modalTarget.classList.add("d-none");
    if (causeIsSelected) {
      this.confirmationTarget.classList.remove("d-none");
      this.causeSelectionTarget.classList.add("d-none");
    }
  }

  displayWidget(e) {
    let data = { showCustomerDonationsWidget: (this.element.dataset.result == 'true'), styles: JSON.parse(this.element.dataset.styles) };
    parent.postMessage(JSON.stringify(data), this.element.dataset.location );
    window.addEventListener("message", this.messageHandler.bind(this));
  }

  handleRequestHeight() {
    const loc = this.element.dataset.location;
    for (let step = 0; step < 15; step++) {
      setTimeout(function() {
        let scrollH = document.querySelector("body").scrollHeight;
        let message = {  customerDonationsHeight: scrollH };
        parent.postMessage(JSON.stringify(message), loc );
      }, step > 0 ? (step * 100) : 100);
    }
  }

  messageHandler(e) {
    try {
      let data = JSON.parse(e.data);
      if(data.requestHeight) this.handleRequestHeight();
      if(data.reloadChosenCause) this.showConfirmationMessage(null, data.quantity, previousVariantId);
      if(data.chosenCauseRemoved) this.resetWidget();
    } catch(e) { }
  }

  resetWidget() {
    this.causeSelectionTarget.dataset["chosenCause"] = false;
    causeIsSelected = false;
    this.causeSelectionTarget.dataset["quantity"] = "";
    this.causeSelectionTarget.dataset["variantId"] = "";
    previousVariantId = null;
    selectedQuantity = null;
    this.causeSelectionTarget.classList.remove("d-none");
    this.confirmationTarget.classList.add("d-none");
    this.changeCauseTarget.querySelector(".change-cause-text").classList.add("d-none");
    this.changeCauseTarget.querySelector(".learn-more-cta").classList.remove("d-none");
    this.handleRequestHeight();
  }

  selectCause(e) {
    let data;
    let variantId;
    if(previousVariantId) {
      variantId = this.variantIds.includes(previousVariantId.toString()) ? this.chosenCauseTarget.dataset["variantId"] : this.chosenCauseTarget.dataset["centsVariantId"];
    } else {
      if(this.variantType) {
        variantId = this.chosenCauseTarget.dataset[this.variantType == "whole" ? "variantId" : "centsVariantId"]
      } else {
        variantId = this.chosenCauseTarget.dataset[(this.roundUp && ((!e.currentTarget?.dataset["custom"]) || this.currencyRate.toFixed() != 1)) || (this.currencyRate.toFixed() != 1 && e.currentTarget?.dataset["custom"]) ? "centsVariantId" : "variantId"];
      }
    }
    if (causeIsSelected) {
      data = {
        changeCustomerCause: true,
        previousVariantId: previousVariantId,
        variantId: variantId,
        productId: this.mainTarget.dataset["productId"],
        quantity: selectedQuantity
      }
      this.showConfirmationMessage(null, selectedQuantity, variantId);
    } else {
      if(!selectedQuantity) {
        const quantity = e.currentTarget.dataset["quantity"];
        selectedQuantity = quantity;
      }
      data = {
        chooseCustomerCause: true,
        variantId: variantId,
        productId: this.mainTarget.dataset["productId"],
        quantity: selectedQuantity
      };
      this.mainTarget.classList.add("main-message-display");
      causeIsSelected = true;
      this.showConfirmationMessage(null, selectedQuantity, variantId);
      this.trackController.trackEvent(e.currentTarget, "added donation to cart", {...this.causeMetaData, ...{ amount: parseInt(e.currentTarget.dataset["quantity"]) }});
    }
    //Update the previous variant id
    previousVariantId = variantId;
    parent.postMessage(JSON.stringify(data), this.element.dataset.location);
  }

  reselectCause() {
    // show modal again
    this.mainTarget.classList.remove("main-message-display");
    this.show();
    this.confirmationTarget.classList.add("d-none");
    this.causeSelectionTarget.classList.remove("d-none");
  }

  showConfirmationMessage(causeName = null, quantity, variantId = null) {
    this.customDisplayTarget.classList.add("d-none");
    // Check if only one cause
    if (this.changeCauseTarget.dataset["singleCause"] == "false") {
      this.changeCauseTarget.querySelector(".change-cause-text").classList.remove("d-none");
      this.changeCauseTarget.querySelector(".learn-more-cta").classList.add("d-none");
    }
    // Set styles in message div
    const donationAmountElement =
      this.confirmationTarget.querySelector(".donation-amount");
    const organizationNameElement =
      this.confirmationTarget.querySelector(".organization-name");

    let organizationName

    if (causeName) {
      organizationName = causeName;
    } else {
      organizationName = this.chosenCauseTarget.querySelector(
        ".cause-organization"
      ).innerHTML;
    }
    
    let currencySymbol = this.element.dataset.currency;

    const i18n = new I18n(TRANSLATIONS);
    i18n.locale = this.element.dataset.locale;
    let amount = 0;
    
    if(this.roundUp && this.centsVariantIds.includes(variantId) && this.productValue == quantity) {
      amount = this.productValue;
    } else {
      amount = this.centsVariantIds.includes(variantId) ? this.productValue : this.wholeProductValue;
      amount = ((this.roundUp || this.fixedWholeAmt ? 1 : this.currencyRate) * (amount * parseFloat(quantity)));
    }
    amount = amount.toFixed(2)
    if (amount.endsWith(".00")) {
      amount = amount.slice(0, -3)
    }

    donationAmountElement.innerHTML = i18n.t("donation", { currency: currencySymbol, amount: amount});
    organizationNameElement.innerHTML = i18n.t("organization", { organizationName: organizationName });

    this.customerDonationsWidgetTarget.classList.add("vh-100");
    this.confirmationTarget.classList.remove("d-none");
    this.causeSelectionTarget.classList.add("d-none");
  }

  setSelectedCause(e) {    
    if(previousVariantId && e.currentTarget.dataset["variantId"].toString() == previousVariantId.toString()) {
      this.dismiss();
      return;
    }

    this.chosenCauseTarget.dataset["variantId"] =
      e.currentTarget.dataset["variantId"];
    
      this.chosenCauseTarget.dataset["centsVariantId"] =
      e.currentTarget.dataset["centsVariantId"];

    this.causeListTargets.forEach((cause) => cause.querySelector(".fa-check-circle").classList.add("d-none"));

    let checkBox = e.currentTarget.querySelector(".fa-check-circle")
    checkBox.classList.remove("d-none");
    this.chosenCauseTarget.querySelector(".cause-icon").innerHTML =
      e.currentTarget.querySelector(".cause-icon").innerHTML;
    this.chosenCauseTarget.querySelector(".cause-organization").innerHTML =
      e.currentTarget.querySelector(".cause-organization").innerHTML;
    
    this.saveCauseMetaData(e.currentTarget.dataset["metadata"]);


    if (causeIsSelected) {
      // Display impact options
      if(!this.roundUp && e.currentTarget.dataset["impactOptions"]?.length) {
        let impactOptions = JSON.parse(e.currentTarget.dataset["impactOptions"]);
        this.donationOptionTargets.forEach((optElm, idx) => {
          optElm.innerHTML = `<p class="my-2">${this.donationOptions[idx]}${impactOptions[idx] ? ` = <span class="accent">${impactOptions[idx]}</span></p>` : ''}`;
        });
      } else if(!this.roundUp) {
        // Display the normal options
        this.donationOptionTargets.forEach((optElm, idx) => {
          optElm.innerHTML = `<p class="my-2">${this.donationOptions[idx]}</p>`;
        });
      }
      this.selectCause(e);
    }

    !causeIsSelected && this.dismiss();
  }

  saveCauseMetaData(metadata) {
    if(metadata) this.causeMetaData = JSON.parse(metadata);
  }

  get trackController() {
    // const element = document.getElementById("customer_donations_widget") || 
    //               document.getElementById("customer_donations_cart_drawer_widget");
   
    return this.application.getControllerForElementAndIdentifier(this.element, "track")
  }
}
