import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["label", "givingAmount"]

  initialize (){
  }

  changeDonationType(e) {
    if(e.currentTarget.value === "percentage"){
      this.givingAmountTarget.setAttribute('max', '100');
    } else {
      this.givingAmountTarget.removeAttribute('max');
    }
  }
}
