import { Controller } from "stimulus"
import { getMetaValue } from "../utils/helpers";

export default class EngagementChartsController extends Controller {
  static targets = [ "visitorEngagementPercentage", "customerEngagementPercentage", "visitorEngagement", "customerEngagement" ]
  static values = {
    "url": String
  }

  async connect() {
    const data = await this.fetchData(); 
    if (!data) return;

    this.updateVisitorEngagementPercentage(data.visitor_engagement_percentage);
    this.updateCustomerEngagementPercentage(data.customer_engagement_percentage);
    this.updateVisitorEngagement(data.visitor_engagement);
    this.updateCustomerEngagement(data.customer_engagement);
  }

  async fetchData() {
    this.setLoadingBlur();
    const response = await fetch(this.urlValue, 
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getMetaValue("csrf-token"),
        },
        credentials: "same-origin",
      }
    )
    if (!response.ok) return null;
    const data = await response.json();
    this.removeLoadingBlur();
    return data;
  }

  updateVisitorEngagementPercentage(percentage) {
    this.visitorEngagementPercentageTarget.innerText = `${percentage}%`
  }

  updateCustomerEngagementPercentage(percentage) {
    this.customerEngagementPercentageTarget.innerText = `${percentage}%`
  }

  updateVisitorEngagement(data) {
    Chartkick.charts['visitorEngagement'].updateData(data);
  }

  updateCustomerEngagement(data) {
    Chartkick.charts['customerEngagement'].updateData(data);
  }

  setLoadingBlur() {
    this.visitorEngagementPercentageTarget.classList.add("blur-self-box");
    this.customerEngagementPercentageTarget.classList.add("blur-self-box");
    this.visitorEngagementTarget.classList.add("blur-self-box");
    this.customerEngagementTarget.classList.add("blur-self-box");
  }

  removeLoadingBlur() {
    this.visitorEngagementPercentageTarget.classList.remove("blur-self-box");
    this.customerEngagementPercentageTarget.classList.remove("blur-self-box");
    this.visitorEngagementTarget.classList.remove("blur-self-box");
    this.customerEngagementTarget.classList.remove("blur-self-box");
  }
}
