import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect () {
    this.hasCrisp = typeof $crisp != "undefined";
  }

  newMessage(e) {
    e.preventDefault();
    if(!this.hasCrisp) return;
    $crisp.push(["do", "message:thread:start", [e.currentTarget.dataset["mesage"]]]);
  }
}
