import { Controller } from "stimulus"
const LEAVING_PAGE_MESSAGE = "Your changes have not been saved. Are you sure you want to exit this page?"
export default class extends Controller {

  formIsChanged(event) {
    this.setChanged("true")
  }

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == "turbolinks:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) { 
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE;
        return event.returnValue;
      }
    } 
  }

  allowFormSubmission(event) {
    this.setChanged("false")
  }

  setChanged(changed) {
    this.data.set("changed", changed)
  }

  isFormChanged() {
    return this.data.get("changed") == "true";
  }
}