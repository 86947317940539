import { Controller } from "stimulus";

export default class ClipboardController extends Controller {
  static targets = ["modal"];
  static values = {
    selector: { type: String, default: "#copy-to-clipboard" },
    debug: { type: Boolean, default: false },
  };

  initialize() {
    let options = {};
    if (this.hasModalTarget) {
      options.container = this.modalTarget;
    } else if (this.element.classList.contains("modal")) {
      options.container = this.element;
    }
    var clipboard = new ClipboardJS(this.selectorValue, options);
    clipboard.on("success", (e) => {
      if (this.debugValue) {
        console.info("Action:", e.action);
        console.info("Text:", e.text);
        console.info("Trigger:", e.trigger);
      }

      const copyElement = this.element.querySelector(this.selectorValue);
      if (copyElement) {
        copyElement.innerHTML = "Copied!"
      }
    });

    clipboard.on("error", (e) => {
      if (this.debugValue) {
        console.error("Action:", e.action);
        console.error("Trigger:", e.trigger);
      }
    });
  }
}
