import { Controller } from "stimulus"
import { renderInModal } from "../utils/modals";

export default class FetchModalController extends Controller {
    static values = {
        location: String,
        target: { type: String, default: '#modal-holder' },
        innerSelector: { type: String, default: '.modal' },
    }

    connect() {
        if (this.isLink) this.setupLinkDefaults();
    }

    get isLink() {
        return this.element.tagName === 'A';
    }

    setupLinkDefaults() {
        this.locationValue = this.element.getAttribute('href');
    }

    async render(e) {
        if (this.isLink) e.preventDefault();

        const response = await fetch(this.locationValue, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        const modalContent = await response.text()

        renderInModal(modalContent, this.targetValue, this.innerSelectorValue);
    }
}