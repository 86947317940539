import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["container"]

    connect() {
      this.retryCount = this.element.dataset["retryCount"];
    }

    checkPayoutStatus(e) {
      const payoutId = e.currentTarget.dataset['payout'];
      let count = 0;
      let warningElement = document.getElementById('warning-element');
      let retryCount = this.retryCount;
      if (warningElement && warningElement.parentNode) {
        warningElement.parentNode.removeChild(warningElement);
      }
      const checkStatus = () => {
        // search doument for element with id warning-element.
        // if it exsists remove that element.
        
        fetch(`/payouts/${payoutId}/payout_status`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => response.json())
          .then(data => {
            if (data.status === 'processed') {
              // reload page
              location.reload();
            } else {
              if (count >= retryCount) {
                // reset button
                // this.containerTarget.classList.remove("mt-4");
                let warningElement = document.createElement("div");
                warningElement.setAttribute("class", "alert alert-warning text-center");
                warningElement.setAttribute("id", "warning-element");
                warningElement.setAttribute("data-cy", "retry-failed");
                warningElement.textContent = "It looks like the payment is still failing. Please try again or contact support if you are unable to get the payment to process.";
                let parentElement = this.containerTarget.parentNode;
                parentElement.insertBefore(warningElement, this.containerTarget);

                this.buttonLoadingController.stopProcessing();
                clearInterval(intervalId);
              }
            }
          })
          .catch(error => console.error(error))
        count += 1;
      }

      const intervalId = setInterval(checkStatus, 2000);
    }

    get buttonLoadingController() {
      return this.application.getControllerForElementAndIdentifier(document.getElementById("button-loading"), "button-loading");
    }
}