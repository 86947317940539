import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "button" ]

    connect() {
        if(this.hasButtonTarget) {
            this.defaultButtonText = this.buttonTarget.textContent;
            var loader = document.createElement("span")
            loader.classList.add("spinner-border", "spinner-border-sm")
            this.loader = loader;
        }
    }


    displayProcessing() {
        if (this.buttonTarget) {
            this.buttonTarget.setAttribute("disabled", "true")
            this.buttonTarget.innerHTML = this.loader.outerHTML;
        } else {
            console.log("no button target")
        }
    }
    stopProcessing() {
      console.log("stop processing")
      this.buttonTarget.removeAttribute("disabled");
      this.buttonTarget.textContent = this.defaultButtonText;
    }
}