// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

export const application = Application.start()
application.debug = process.env.NODE_ENV === 'development'

const context = require.context("controllers", true, /_controller\.js$/)

application.load(definitionsFromContext(context))
