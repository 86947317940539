import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "pagination", "searchInput", "dropdown", "itemType", "results" ];

  connect() {
    this.givingItemType = "product";
    this.existingGivingItems = JSON.parse(this.element.dataset["existingIds"]) || [];
    const ctrl = this;
    $(document.body).on("click", function(e) {
      ctrl.hideDropdown();
    });
    var loader = document.createElement("span")
    loader.classList.add("spinner-border");
    loader.style = "margin-left:40%;"
    this.loader = loader;
  }

  toggleDropdown(e) {
    $(this.dropdownTarget).dropdown('toggle');
  }

  hideDropdown() {
    let menu = this.dropdownTarget.querySelector(".dropdown-menu");
    let visible = $(menu).is(":visible");
    if(visible) {
      $(menu).dropdown('hide');
      menu.classList.remove("show")
      menu.classList.add("hide");
    }
  }
  setSearchUrl(e) {
    this.givingItemType = e.currentTarget.dataset.itemType;
    this.itemTypeTargets.forEach((itemType) => {
      itemType.classList.remove("theme-tag-highlighted");
    });
    e.currentTarget.classList.add("theme-tag-highlighted")
    this.dropdownTarget.dataset.url = e.currentTarget.dataset.url;
    this.fetchResults();
  }
  searchItemSelected(e) {
    this.existingGivingItems.push(e.currentTarget.dataset.id);
    e.currentTarget.remove();
  }

  get searchController() {
    return this.application.getControllerForElementAndIdentifier(this.dropdownTarget, "search");
  }

  searchPress(e) {
    if(e.keyCode != 13){
      return;
    }
    e.preventDefault();    
    this.fetchResults();
  }

  fetchResults() {  
    if(this.query == "") {
      this.reset()
      return
    }
    if(this.query == this.previousQuery && this.dropdownTarget.dataset.url == this.previousSearchUrl) {
      return
    }
    this.previousQuery = this.query;
    this.previousSearchUrl = this.dropdownTarget.dataset.url;

    const url = new URL(this.dropdownTarget.dataset.url);
    url.searchParams.append("query", this.query);
    url.searchParams.append("existing_ids", this.existingGivingItems?.toString());

    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()
    this.resultsTarget.innerHTML = this.loader.outerHTML;

    fetch(url, { signal: this.abortController.signal })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
      .catch(() => {})
  }

  selectItem(e) {
    this.previousQuery = null;
    this.queryTarget.value = e.currentTarget.innerText;
    this.resultsTarget.innerHTML = "";
  }

  navigateResults(event) {
    if(this.searchResultsController) {
      this.searchResultsController.navigateResults(event)
    }
  }

  // private

  reset() {
    this.resultsTarget.innerHTML = ""
    this.searchInputTarget.value = ""
    this.previousQuery = null
  }

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
    }
  }

  get query() {
    return this.searchInputTarget.value
  }

  get searchResultsController() {
    return this.application.getControllerForElementAndIdentifier(this.resultsTarget.firstElementChild, "search-results")
  }
}