import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import confetti from "canvas-confetti";
export default class extends Controller {
  static targets = [ "form", "pledged", "causeId", "causeLoading", "causeButton", "cause", "causeSelector", "chosenCauseMedia", "chosenCauseContent" ] 

  connect() {
    var loader = document.createElement("span")
    loader.classList.add("spinner-border");
    this.loader = loader;
  }

  initialize() {
    this.chosenCause = null;
  }

  closeFrame(e) {
    parent.postMessage("close_popup", this.element.dataset.baseUrl);
  }

  submit(e) {
    this.causeIdTarget.value = e.currentTarget.dataset.id;
    this.chosenCause = e.currentTarget.cloneNode(true);
    this.displayProcessing(e);
    Rails.fire(this.formTarget, 'submit')
  }

  displayProcessing(e) {
    e.currentTarget.innerHTML = this.loader.outerHTML;
    this.causeTargets.forEach((btn) => {
      if(btn.id !== e.currentTarget.id) {
        btn.setAttribute("disabled", "true")
      }
    });
    this.causeButtonTargets.forEach((btn) => {
      btn.classList.add("disabled")
    });
  }

  onPostSuccess(e) {
    this.causeSelectorTarget.classList.add("d-none");
    this.chosenCauseMediaTarget.innerHTML = this.chosenCause.querySelector(".card-media").innerHTML;
    this.chosenCauseContentTarget.innerHTML = this.chosenCause.querySelector(".cause-content").innerHTML;
    this.pledgedTarget.classList.add("d-block");
    this.pledgedTarget.classList.remove("d-none");
    let base_url = this.element.dataset.baseUrl;
    this.showConfetti();
  }

  onPostError(e) {
    //Display the error alert

  }

  showConfetti() {
    let accentColor = this.element.dataset.accentcolor
    var duration = 3 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function() {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2, }, colors: [accentColor] }));
      confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2, }, colors: [accentColor] }));
    }, 250);
  }
}
