import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
  }

  async getLocale(e) {
    e.preventDefault();
    const locale = e.currentTarget.value;
    window.location.search = `locale=${locale}`;
  }
}