import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "modal"]

  initialize() {
  }

  // show(e) {
  //   this.mainTarget.classList.add("d-none")
  //   this.modalTarget.classList.remove("d-none")
  // }
  // dismiss(e) {
  //   e.preventDefault();
  //   this.modalTarget.classList.add("d-none")
  //   this.mainTarget.classList.remove("d-none")
  // }

}
