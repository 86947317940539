import Cookies from './cookies';

const config = {
  urlPrefix: "",
  visitsUrl: "/ahoy/visits",
  eventsUrl: "/ahoy/events",
  page: null,
  platform: "Web",
  useBeacon: true,
  startOnReady: false,
  trackVisits: true,
  cookies: true,
  cookieDomain: null,
  visitParams: {},
  withCredentials: false,
  visitDuration: 4 * 60,
  visitorDuration: 2 * 365 * 24 * 60
};

const ahoy = window.ahoy || window.Ahoy || {};

ahoy.configure = function (options) {
  for (const key in options) {
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      config[key] = options[key];
    }
  }
};

ahoy.configure(ahoy);

const $ = window.jQuery || window.Zepto || window.$;
let visitId, visitorId, track;
let isReady = false;
const queue = [];
const canStringify = typeof(JSON) !== "undefined" && typeof(JSON.stringify) !== "undefined";
let eventQueue = [];


function eventsUrl() {
  return config.urlPrefix + config.eventsUrl;
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
function canTrackNow() {
  return (config.useBeacon || config.trackNow) && isEmpty(config.headers) && canStringify && typeof(window.navigator.sendBeacon) !== "undefined" && !config.withCredentials;
}

function serialize(object) {
  const data = new FormData();
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      data.append(key, object[key]);
    }
  }
  return data;
}

function setCookie(name, value, ttl) {
  Cookies.set(name, value, ttl, config.cookieDomain || config.domain);
}

function getCookie(name) {
  return Cookies.get(name);
}

function setReady() {
  let callback;
  while ((callback = queue.shift())) {
    callback();
  }
  isReady = true;
}

ahoy.ready = function (callback) {
  if (isReady) {
    callback();
  } else {
    queue.push(callback);
  }
};

function documentReady(callback) {
  if (document.readyState === "interactive" || document.readyState === "complete") {
    setTimeout(callback, 0);
  } else {
    document.addEventListener("DOMContentLoaded", callback);
  }
}

function generateId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function saveEventQueue() {
  if (config.cookies && canStringify) {
    setCookie("virtue_events", JSON.stringify(eventQueue), 1);
  }
}

function csrfToken() {
  const meta = document.querySelector("meta[name=csrf-token]");
  return meta && meta.content;
}

function CSRFProtection(xhr) {
  const token = csrfToken();
  if (token) xhr.setRequestHeader("X-CSRF-Token", token);
}

function eventData(event) {
  const data = {
    events: [event]
  };
  if (config.cookies) {
    data.visit_token = event.visit_token;
    data.visitor_token = event.visitor_token;
  }
  delete event.visit_token;
  delete event.visitor_token;
  return data;
}

function trackEvent(event) {
  ahoy.ready(function () {
    sendRequest(eventsUrl(), eventData(event), function () {
      // remove from queue
      for (let i = 0; i < eventQueue.length; i++) {
        if (eventQueue[i].id === event.id) {
          eventQueue.splice(i, 1);
          break;
        }
      }
      saveEventQueue();
    });
  });
}

function sendRequest(url, data, success) {
  if (canStringify) {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.withCredentials = config.withCredentials;
    xhr.setRequestHeader("Content-Type", "application/json");
    for (const header in config.headers) {
      if (Object.prototype.hasOwnProperty.call(config.headers, header)) {
        xhr.setRequestHeader(header, config.headers[header]);
      }
    }
    xhr.onload = function () {
      if (xhr.status === 200) {
        success();
      }
    };
    CSRFProtection(xhr);
    xhr.send(JSON.stringify(data));
  }
}

function createVisit() {
  isReady = false;

  visitId = ahoy.getVisitId();
  visitorId = ahoy.getVisitorId();
  track = getCookie("ahoy_track");

  if (config.cookies === false || config.trackVisits === false) {
    setReady();
  } else if (visitId && visitorId && !track) {
    setReady();
  } else {
    if (!visitId) {
      visitId = generateId();
      setCookie("virtue_visit", visitId, config.visitDuration);
    }

    if (getCookie("virtue_visit")) {

      if (!visitorId) {
        visitorId = generateId();
        setCookie("virtue_visitor", visitorId, config.visitorDuration);
      }
      destroyCookie("ahoy_track");
    } else {
      setReady();
    }
  }
}

ahoy.getVisitId = ahoy.getVisitToken = function () {
  return getCookie("virtue_visit");
};

ahoy.getVisitorId = ahoy.getVisitorToken = function () {
  return getCookie("virtue_visitor");
};

ahoy.track = function (name, properties) {
  const event = {
    name: name,
    properties: properties || {},
    time: (new Date()).getTime() / 1000.0,
    id: generateId(),
    js: true
  };

  ahoy.ready(function () {
    if (config.cookies && !ahoy.getVisitId()) {
      createVisit();
    }

    ahoy.ready(function () {

      event.visit_token = ahoy.getVisitId();
      event.visitor_token = ahoy.getVisitorId();

      eventQueue.push(event);
      saveEventQueue();

      setTimeout(function () {
        console.log(event, 'event');
        trackEvent(event);
      }, 1000);
    });
  });

  return true;
};

try {
  eventQueue = JSON.parse(getCookie("virtue_events") || "[]");
} catch (e) {
}

for (let i = 0; i < eventQueue.length; i++) {
  trackEvent(eventQueue[i]);
}

ahoy.start = function () {
  createVisit();

  ahoy.start = function () {};
};

documentReady(function () {
  if (config.startOnReady) {
    ahoy.start();
  }
});

export default ahoy;
