import { Controller } from "stimulus"
import { I18n } from "i18n-js";
import { TRANSLATIONS } from "../locales/translations.js";

export default class extends Controller {
  static targets = ["modal", "levelCartValue", "levelRemainingValue", 
                    "levelDonationValue", "progressBar", "earnedText", 
                    "remainingText", "donationAmount", "cause"];

  initialize() {
  }

  connect() {
    if(this.element.dataset.cartDonationsLevels) {
      this.cartDonationsLevels = JSON.parse(this.element.dataset.cartDonationsLevels);
    }
    this.currencySymbol = this.element.dataset.currency;
    this.currencyRate = this.element.dataset.currencyRate;
  }

  displayBanner(e) {
    let data = { showBanner: (this.element.dataset.result == 'true'), causes: this.element.dataset.causes, styles: JSON.parse(this.element.dataset.styles) };
    parent.postMessage(JSON.stringify(data), this.element.dataset.location );
    window.addEventListener("message", this.messageHandler.bind(this));
  }

  chooseCause(e) {
    this.causeTargets?.forEach((t) => {
      t.classList.remove("chosen-cause");
    });
    e.currentTarget.classList.add("chosen-cause");
    let data = {selectedCauseId: e.currentTarget.dataset.causeId};
    parent.postMessage(JSON.stringify(data), this.element.dataset.location );
  }

  handleVariantChanged(data) {
    if(this.element.dataset.storeGiving && 
      this.element.dataset.pledgeType == "per_product" && 
      this.element.dataset.donationType == "percentage") {
      // Re-calculate the giving amount
      let amt = (parseFloat(this.element.dataset.givingAmount) / 100);
      let newAmount = (parseFloat(data.variantPrice) * amt); 
      if(this.donationAmountTarget) this.donationAmountTarget.innerHTML = `${this.currencySymbol}${newAmount.toFixed(2)}`;
    }
  }

  handleCartUpdated(data) {
    this.cartTotal = data.cartTotal;
    this.calculateCartValues();

    const i18n = new I18n(TRANSLATIONS);
    i18n.locale = this.element.dataset.locale;
    
    //Update the banners to reflect the level
    this.levelCartValueTarget.innerHTML = `${this.currencySymbol}${this.cartTargetValue.toFixed(2)}`;
    if(this.remainingValue > 0) {
      this.levelRemainingValueTarget.innerHTML = `${this.currencySymbol}${this.remainingValue.toFixed(2)}`;
    }
    //Update the progress bar
    this.progressBarTarget.style["width"] = `${this.cartProgress}%`;
    if(this.cartProgress == 100) {
      this.progressBarTarget.style["border-radius"] = '11px';
    }

    if(this.redeemedLevel){
      let redeemedDonationText = i18n.t("earned");
      switch(this.redeemedLevel.donation_type) {
        case 'percentage':
          redeemedDonationText += `<span class="accent fw-700">${this.redeemedLevel.donation_value}%</span>`;
          break;
        case 'fixed':
          redeemedDonationText += `<span class="accent fw-700">${this.currencySymbol}${this.redeemedLevel.donation_value}</span>`;
          break;
      }
      if(this.cartTargetValue > 0) redeemedDonationText += '.';
      else redeemedDonationText += i18n.t("support");
      this.earnedTextTarget.innerHTML = redeemedDonationText;
      if(this.cartTargetValue == 0) {
        this.remainingTextTarget.classList.add("d-none");
      } else {
        this.levelDonationValueTarget.innerHTML = this.donationText;
      }
    }
    //Update the height of the widget in case
    this.handleRequestHeight();
  }

  calculateCartValues() {
    //Get the cart donation level
    let cartLevel = null;
    for (let i = 0; i < this.cartDonationsLevels.length; i++) {
      let level = this.cartDonationsLevels[i];
      if(parseInt(level.cart_value) >= this.cartTotal) {
        cartLevel = level;
        break;
      } else {
        this.redeemedLevel = level;
      }
    };
    if(cartLevel)  {
      this.cartTargetValue = parseInt(cartLevel.cart_value);
      this.donationValue = parseInt(cartLevel.donation_value);
      //Calculate the progress toward the level
      this.cartProgress = this.cartTotal > this.cartTargetValue ? 100 : ((this.cartTotal / this.cartTargetValue) * 100);
      this.remainingValue = (this.cartTargetValue - this.cartTotal);
      //Update the donation text
      switch(cartLevel.donation_type) {
        case 'percentage':
          this.donationText = `${this.donationValue}%`;
          break;
        case 'fixed':
          this.donationText = `${this.currencySymbol}${this.donationValue}`;
          break;
      }
    } else {
      this.cartProgress = 100;
      this.cartTargetValue = 0;
    }
  }

  handleRequestHeight() {
    const loc = this.element.dataset.location;
    for (let step = 0; step < 3; step++) {
      setTimeout(function() {
        let scrollH = document.body.scrollHeight;
        let message = {  height: scrollH };
        parent.postMessage(JSON.stringify(message), loc );
      }, step > 0 ? (step * 100) : 100);
    }
  }

  messageHandler(e) {
    try {
      let data = JSON.parse(e.data);
      if(data == undefined) return;
      if(data.requestHeight) this.handleRequestHeight();
      if(data.cartUpdated) this.handleCartUpdated(data);
      if(data.variantChanged) this.handleVariantChanged(data);
    } catch(e) { console.log(e) }
  }

  show(e) {
    this.modalTarget.classList.remove("d-none")
  }
  dismiss(e) {
    e.preventDefault();
    this.modalTarget.classList.add("d-none")
  }
}
