import { Controller } from "stimulus"
import InfiniteScroll from 'infinite-scroll'
import Sticky from 'sticky-js'

export default class extends Controller {
  static targets = ["entries", "pagination"]

  initialize() {
    this.setupScroll();
  }

  setupScroll() {
    let entries = this.entriesTarget
    let paginate = this.paginationTarget
    let lastPage = paginate.querySelector("a[rel='next']") ? false : true;
    this.scroll = new InfiniteScroll( `#${this.element.id}`, {
      history: false,
      scrollThreshold: this.element.dataset["scroll"] || 80,
      append: false,
      responseBody: 'json',
      elementScroll: this.element.dataset["scrollElement"] || false,
      status: '.scroller-status',
      path: function() {
        let nextElm = paginate.querySelector("a[rel='next']")
        if(lastPage) {
          return false;
        } else if(nextElm) {
          return nextElm.getAttribute("href");
        } else {
          return this.element.dataset["fallbackPath"];
        }
      },
      checkLastPage: function () {
        return lastPage;
      }
    });
    this.scroll.on( 'load', function( data, url ) {
      // data is JSON
      entries.insertAdjacentHTML('beforeend', data.entries);
      paginate.innerHTML = data.pagination;
      lastPage = data.last_page;
      if(lastPage) {
        this.scroll.destroy();
      }
    });
  }

  connect() {
  }

  reset() {
    if(this.scroll) {
      this.scroll.destroy();
    } 
    this.setupScroll();
  }
}
