import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "form"];

  initialize() {
  }

  searchPress(e) {
    if(e.keyCode == 13){
      e.preventDefault();
      this.doSearch(e);
    }
  }
  doSearch(e) {
    e.preventDefault();
    let searchQuery = e.currentTarget.parentElement.parentElement.querySelector("input").value;
    const data = new URLSearchParams(new FormData(this.formTarget));
    fetch(this.formTarget.getAttribute("action"), {
      method: 'post',
      body: data,
      headers: {
        'X-Requested-With':'XMLHttpRequest'
      }
    })
    .then(() => {
      this.unsavedFormController.allowFormSubmission(e);
      window.location.href = `${this.element.dataset.searchPath}?search=${searchQuery}#infinite-scroll-causes`;
    });
  }
  get unsavedFormController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("unsaved-changes"), "unsaved-changes");
  }
}
