import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["perOrderForm", "recurringForm", "annualForm", "annualStoreGiving"];
  static values = {
    current: { type: String }
  }

  connect() {
    this.updateFormVisibility(this.currentValue);
  }

  pledgeTypeChanged(event) {
    const value = event.currentTarget.value;
    this.updateFormVisibility(value);
  }

  annualAmountChanged(event) {
    if (!this.hasAnnualStoreGivingTarget) return;

    const value = event.currentTarget.value;
    const amountPerMonth = Math.round(value / 12, 2);
    this.annualStoreGivingTarget.value = amountPerMonth;
  }

  updateFormVisibility(value) {
    if (value === "per_order") {
      $(this.perOrderFormTarget).show();
      $(this.recurringFormTarget).hide();
      $(this.annualFormTarget).hide();
      return;
    }
    if (value === "recurring") {
      $(this.perOrderFormTarget).hide();
      $(this.recurringFormTarget).show();
      $(this.annualFormTarget).hide();
      return;
    }
    if (value === "annually") {
      $(this.perOrderFormTarget).hide();
      $(this.recurringFormTarget).hide();
      $(this.annualFormTarget).show();
      return;
    }
    $(this.perOrderFormTarget).hide();
    $(this.recurringFormTarget).hide();
    $(this.annualFormTarget).hide();
  }
}
