import { Controller } from "stimulus"
import { hexToRgba } from "../utils/helpers"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["picker", "impactCalculator", "logoImage"]

  initialize() {
    this.pickerTargets.forEach((el, i) => {
      $(el).colorpicker({
        autoInputFallback: false,
        autoHexInputFallback: false
      });
    });
  }
  connect() {
    this.root = document.documentElement;
  }

  changeLogo(e) {
    this.readImageToBase64(e.currentTarget, function (image_url) {
      document.getElementById("widget-logo").src = image_url;
      let svg = document.querySelector("svg#virtue-logo");
      if (svg) svg.style["display"] = "none";
    })
  }

  showPicker(e) {
    let form = e.currentTarget.parentNode.parentNode.querySelector('.form-control');
    $(form).colorpicker('show');
  }

  handleChanged(e) {
    let prefix = '';
    if (e.currentTarget.dataset.cssprefix) prefix = e.currentTarget.dataset.cssprefix;
    let suffix = '';
    if (e.currentTarget.dataset.csssuffix) suffix = e.currentTarget.dataset.csssuffix;
    let val = e.currentTarget.value;
    if (val == "true") val = 1;
    else if (val == "false") val = 0;
    if (document.getElementById(`${e.currentTarget.dataset.csskey}-preview`)) {
      document.getElementById(`${e.currentTarget.dataset.csskey}-preview`).style.backgroundColor = `${prefix}${val}${suffix}`
    }
    this.root.style.setProperty(`--${e.currentTarget.dataset.csskey}`, `${prefix}${val}${suffix}`);
  }

  handleRgbChange(e) {
    this.root.style.setProperty(`--${e.currentTarget.dataset.rgbkey}`, hexToRgba(e.currentTarget.value, 0.1));
    if (e.currentTarget.dataset.dark) this.root.style.setProperty(`--${e.currentTarget.dataset.rgbkey}-dark`, hexToRgba(e.currentTarget.value, e.currentTarget.dataset.dark));
    if (e.currentTarget.dataset.darker) this.root.style.setProperty(`--${e.currentTarget.dataset.rgbkey}-darker`, hexToRgba(e.currentTarget.value, e.currentTarget.dataset.darker));
  }

  showPostPurchase(e) {
    if (e.currentTarget.checked === true) {
      e.currentTarget.value = true;
      document.getElementById('post_purchase_block').style.display = "inline";
      this.loadMore('/post-purchase', 'post_purchase_div')
    } else {
      e.currentTarget.value = false;
      document.getElementById('post_purchase_block').style.display = "none";
      document.getElementById('post_purchase_widget').remove();
    }
  }

  showImpactCalculator(e) {
    if (e.currentTarget.checked === true) {
      e.currentTarget.value = true;

      document.getElementById('impact_calculator_block').style.display = "inline";
      this.loadMore('/impact-calculator', 'impact_calculator_div')
    } else {
      e.currentTarget.value = false;
      document.getElementById('impact_calculator_block').style.display = "none";
      document.getElementById('impact_calculator_widget').remove();
    }
  }
  readImageToBase64(input, callback) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        callback(e.target.result);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  loadMore(url, div_id) {
    Rails.ajax({
      type: 'GET',
      url: url + `?background_color=${encodeURIComponent(this.backgroundColorTarget.value)}&font_color=${encodeURIComponent(this.fontColorTarget.value)}`,
      dataType: 'json',
      success: (data) => {
        document.getElementById(div_id).insertAdjacentHTML('beforeend', data.entries)
      }
    }
    )
  }
}
