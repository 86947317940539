import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["btnAddOn", "btnChangePlan"]

  initialize () {
  }

  toggleAddOnButton(e) {
    if(e.currentTarget.checked) {
      this.btnChangePlanTarget.classList.add("d-none");
      this.btnAddOnTarget.classList.remove("d-none");
    } else {
      this.btnChangePlanTarget.classList.remove("d-none");
      this.btnAddOnTarget.classList.add("d-none");
    }
  }
}
