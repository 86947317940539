const DEFAULT_MODAL_CONTAINER = '#modal-holder';
const DEFAULT_MODAL_INNER = '.modal';

export function renderInModal(content, modalContainerSelector = DEFAULT_MODAL_CONTAINER, modalSelector = DEFAULT_MODAL_INNER) {
    const holder = document.querySelector(modalContainerSelector);
    holder.innerHTML = content;
    $(holder).find(modalSelector).modal(); 
}

function showModal() {
    const holder = document.querySelector(DEFAULT_MODAL_CONTAINER);
    $(holder).find(DEFAULT_MODAL_INNER)?.modal(); 
}

function documentReady(callback) {
    if (document.readyState === "interactive" || document.readyState === "complete") {
        setTimeout(callback, 0);
    } else {
        document.addEventListener("DOMContentLoaded", callback);
    }
}

documentReady(showModal)