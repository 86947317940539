import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "button", "contact", "form", "iconOrLogo", "chosenCauseIcon", "logoFile" ]


  connect () {
    this.validFormSubmitted = false;
  }
  
  toggleContact(e) {
    if(e.currentTarget.value == "true") {
      this.contactTarget.classList.remove("d-none")
    } else {
      this.contactTarget.classList.add("d-none")
    }
  }

  validateForm(e) {
    if(this.validFormSubmitted == true) return true;
    e.preventDefault();
    e.stopPropagation();
    let isValid = false;
    let checkedIconTarget = this.iconOrLogoTargets.find((r) => r.checked == true);
    if(checkedIconTarget.value == "icon") {
      if(this.chosenCauseIconTarget.value) {
        isValid = true;
      } else {
        // display error
        this.displayError(this.chosenCauseIconTarget, "Please choose an icon before submitting your cause. If you do not like any of the icons choose one for now and message us at support to get a suitable icon.")
      }
    } else {
      if(this.logoFileTarget.files?.length) {
        isValid = true;  
      } else {
        // display error
        this.displayError(this.logoFileTarget, "Please upload the logo or icon for your cause before continuing.")
      }
    }
    if(isValid) {
      console.log("is valid");
      this.loadController.displayProcessing(e);
      this.validFormSubmitted = true;
      // Rails.fire(this.formTarget, 'submit');
      this.formTarget.submit();
    }
  }

  onPostSuccess(e) {
    window.scrollTo(0, 0);
    window.location.reload();
  }

  displayError(el, error) {
    if(el) {
      let errorElement = el.closest(".form-group").querySelector("div.invalid-feedback")
      if(errorElement == undefined) {
          errorElement = document.createElement("div")
          errorElement.setAttribute("class", "invalid-feedback")
          el.parentNode.insertBefore(errorElement, el.nextSibling)
      }
      el.classList.add("is-invalid")
      errorElement.textContent = error;
    }
  }

  get loadController() {
    return this.application.getControllerForElementAndIdentifier(this.formTarget, "load");
  }

}