import { Controller } from "stimulus"

/**
 * Control visiblity of child elements.
 * 
 * Example:
 * ```html
 * <div data-controller="visibility">
 *  <input type="checkbox" data-action="visbility#toggle" />
 *  <p data-visibility-target="target">Visible</p>
 *  <p data-visibility-target="target" class="d-none">Hidden</p>
 * </div>
 * ```
 * 
 * Checking/unchecking the checkbox will toggle which section is
 * displayed.
 * 
 * The target and the action trigger element must have a common
 * (grand)parent of this controller.
 */
export default class VisibilityController extends Controller {
  static targets = [
    "target"
  ]
  static values = {
    key: String
  }

  showTarget() {
    this.targetTargets.forEach(target => {
      target.classList.remove('d-none')
    })
  }
  hideTarget() {
    this.targetTargets.forEach(target => {
      target.classList.add('d-none')
    })
  }
  toggleTargetVisible() {
    this.targetTargets.forEach(target => {
      target.classList.toggle('d-none')
    })
  }
}
