import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]
  initialize() {
    let selected_collections = document.getElementById("selected_products").children;
    let selected_ids = [];
    let i=0;
    let all_collections = document.getElementsByClassName("sellect-collection-popup");
    for (i = 0; i < selected_collections.length; i++) {
        selected_ids.push(selected_collections[i].id);
      }
    for (i = 0; i < all_collections.length; i++) {
      if(selected_ids.includes(all_collections[i].value)) {
        all_collections[i].checked = true;
      }
    }
    this.outputTarget.textContent = `Selected ${selected_collections.length} collections`;
  }
  connect() {
  }

  perform(e) {
    let body = document.getElementById("selected_products");
    let checked = this.element.querySelectorAll('input:checked');
    this.outputTarget.textContent = `Selected ${checked.length} collections`;
    if (e.currentTarget.checked) {
      let element =`<div class="list-group-item" id="${e.currentTarget.value}" data-parent="new"> <input type="checkbox" name="collection_ids[]" id="collection_ids_" value="${e.currentTarget.value}" hidden="hidden" checked="checked" data-action="click->product-select#calculate"> ${e.currentTarget.parentElement.textContent} <button aria-label="Close" class="close" data-dismiss="modal" type="button" data-action="click->product-select#calculate"><span aria-hidden="true" >×</span></button></div>`;
      let child = document.createElement('div');
      child.innerHTML = element;
      child = child.firstChild
      body.appendChild(child);
    } else {
      document.getElementById(e.currentTarget.value).remove();
      document.getElementById("defaultUnchecked").checked = false;
    }
    if(checked.length > 0 ){
      document.getElementById("collection-select-info").style.display = 'none';
    }else
    {
      document.getElementById("collection-select-info").style.display = 'inline-block';
    }
    document.getElementById("select_collections_counter").textContent = `${checked.length} ${ checked.length == 1 ? 'collection' : 'collections' } selected`;
    this.check_collections();
  }

  check_all(e){
    let body = document.getElementById("selected_products");
    let all_collections =  document.getElementsByClassName("sellect-collection-popup");
    let i = 0 ;
    if (e.currentTarget.checked){
     for (i = 0; i < all_collections.length; i++) {
        if (all_collections[i].checked === false) {
          all_collections[i].checked = true;
          let element =`<div class="list-group-item" id="${all_collections[i].value}" data-parent="new"> <input type="checkbox" name="collection_ids[]" id="${all_collections[i].value}" value="${all_collections[i].value}" hidden="hidden" checked="checked"> ${all_collections[i].parentElement.textContent} <button aria-label="Close" class="close" data-dismiss="modal" type="button" data-action="click->product-select#calculate"><span aria-hidden="true">×</span></button></div>`;
          let child = document.createElement('div');
          child.innerHTML = element;
          child = child.firstChild
          body.appendChild(child);
        }
      }
      let checked = this.element.querySelectorAll('input:checked');
      document.getElementById("select_collections_counter").textContent = `Selected ${checked.length - 1} collections`;
      this.outputTarget.textContent = `Selected ${checked.length - 1} collections`;
      document.getElementById("collection-select-info").style.display = 'none';
    }else
    {
      document.getElementById("selected_products").innerHTML = ""
      let checked = this.element.querySelectorAll('input:checked');
      for (i=0; i < checked.length; i++) {
        checked[i].checked = false;
      }
      document.getElementById("select_collections_counter").textContent = `0 collections selected`;
      this.outputTarget.textContent = `Selected 0 collections`;
    }
    this.check_collections();
  }

  clear_all(e) {
    let all_collections = document.getElementsByClassName("sellect-collection-popup");
    let saved_collections = JSON.parse(document.getElementById('saved-collections').innerText);
    let checked = document.getElementById("selected_products").children;
     for (let i = 0; i < checked.length; i++) {
      if(checked[i].dataset.parent === "new") {
          checked[i].remove();
        }
      }
      if(checked[checked.length - 1] && checked[checked.length - 1].dataset.parent === "new") {
          checked[checked.length - 1].remove();
        }
    // document.getElementById("selected_products").innerHTML = ""
    let new_checked = document.getElementById("selected_products").children;
    if (new_checked.legth < 1 ) {
    document.getElementById("collection-select-info").style.display = 'inline'
    }
    document.getElementById("select_collections_counter").textContent = `${new_checked.length} collections selected`;
    this.check_collections();
  }

  check_collections() {
    if ( document.getElementById('product_select_type_selected_products').checked == true && document.getElementById('selected_products').children.length < 1)
    {
      let alert = document.getElementById('collection-alert')
      if (alert) alert.style.display = "block";
      document.getElementById('submit_button').disabled = true;

    } else{
      let alert =  document.getElementById('collection-alert')
      if (alert) alert.style.display = "none";
      document.getElementById('submit_button').disabled = false;
    }

  }

  redirect(event) {
    window.open(event.currentTarget.href, '_blank')
  }
}
