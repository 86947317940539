$(document).on('turbolinks:load', function(e) {
  $('.side-nav .side-nav-menu li.nav-item a.dropdown-toggle').on('click', function(e) {
    e.preventDefault();
    if ($(this).parent().hasClass("open")) {
        $(this).parent().children('.dropdown-menu').slideUp(200, function() {
            $(this).parent().removeClass("open");
        });
    } else {
        $(this).parent().parent().children('li.open').children('.dropdown-menu').slideUp(200);
        $(this).parent().parent().children('li.open').children('a').removeClass('open');
        $(this).parent().parent().children('li.open').removeClass("open");
        $(this).parent().children('.dropdown-menu').slideDown(200, function() {
            $(this).parent().addClass("open");
        });
    }
  });
  $('[data-toggle="popover"]').popover({ boundary: 'window' });
});