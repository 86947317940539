import { Controller } from "stimulus";
import { getMetaValue } from "../utils/helpers";

export default class extends Controller {
  static targets = ["banner"];
  static values = {
    feature: String,
    shown: { type: Boolean, default: false },
    id: { type: String, default: new Date().getTime().toString() }
  }

  async initialize() {
    this._markBannerWithId();
    if (!this._moveElementToHeader()) return;
  }

  toggleShown() {
    this.shownValue = !this.shownValue;
    this.setVisibility();
  }

  async setVisibility() {
    if (!this.shownValue) {
      this.movedBannerTarget.classList.add('d-none');
      return;
    }

    this.movedBannerTarget.classList.add('d-none');
    if (await this._hasFeature()) return;

    this.movedBannerTarget.classList.remove('d-none');
  }

  _markBannerWithId() {
    this.bannerTarget.setAttribute('id', `banner_${this.idValue}`);
  }

  _moveElementToHeader() {
    if (!this.headerContainer) {
      console.error("FeatureGateBannerController cannot run because there is no \'#header\' container");
      return false;
    }
    this.headerContainer.appendChild(this.bannerTarget);
    return true;
  }

  async _hasFeature() {
    const params = new URLSearchParams({
      feature: this.featureValue,
    })
    const response = await fetch("/subscription/feature_check?" + params.toString(), {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
      credentials: "same-origin",
    })
    const result = await response.json()
    // This service returns true when the feature is _gated_, not when it's available
    return !result;
  }

  get headerContainer() {
    return document.querySelector('#header');
  }

  get movedBannerTarget() {
    return this.headerContainer.querySelector(`#banner_${this.idValue}`);
  }
}
