import { Controller } from "stimulus"
import moment from 'moment';
export default class extends Controller {
    static targets = [ "date_range" ]
    connect() {
        if(this.date_rangeTarget) {
            let selectedRange = false
            let ctrl = this;
            let ranges = {
              'Today': [moment(), moment()],
              'Last 7 days': [moment().subtract(6, 'days'), moment()],
              'Last 14 days': [moment().subtract(13, 'days'), moment()],
              'Last 30 days': [moment().subtract(29, 'days'), moment()],
              'This week': [moment().startOf('week'), moment().endOf('week')],
              'This month': [moment().startOf('month'), moment().endOf('month')],
              'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
              'All time': [moment(this.date_rangeTarget.dataset.firstDate), this.date_rangeTarget.dataset.lastDate ? moment(this.date_rangeTarget.dataset.lastDate) : moment()]
            }
            if(this.date_rangeTarget.dataset.upcoming == "1") {
              ranges['Upcoming'] = [moment().startOf('day'), this.upcomingPayoutDate()]
            }
            $(this.date_rangeTarget).daterangepicker({
                showCustomRangeLabel: false,
                alwaysShowCalendars: true,
                autoApply: false,
                opens: this.date_rangeTarget.dataset.orientation || 'left',
                ranges: ranges,
                locale: {
                  format: "DD/MM/YYYY"
                }
            }).on('apply.daterangepicker', function(e, picker) {
                if(selectedRange) {
                    picker.show();
                    selectedRange = false;
                } else {
                  ctrl.submit(e)
                }
            });
            $(".ranges ul li").on("click", function() {
                selectedRange = $(this).text();
            });
        }
    }
    initialize() {
    }
    rangeSelected(e, picker) {
    }
    submit(e) {
        this.element.submit()
    }
    upcomingPayoutDate() {
      return moment().startOf('month').add(19, 'days').add(1, 'month')
    }

    async changeGivingType(e) {
      e.preventDefault();
      const givingType = e.currentTarget.value;
      window.location.search = `giving_type=${givingType}`
    }
}
