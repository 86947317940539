import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];
  static values = {
    index: { type: Number, default: 0 },
  }

  show(event) {
    event.preventDefault();
    this.showManual(this.indexValue);
  }

  showManual(modalIndex) {
    $(".modal").modal("hide");
    $(this.modalTargets[modalIndex]).modal("show");
    $(this.modalTargets[modalIndex]).find("input[type='text']:first").focus();
  }

  dismiss(e) {
    document.body.classList.remove("modal-open");
    this.modalTargets[0].removeAttribute("style");
    this.modalTargets[0].classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
  }

  reload(e) {
    window.location.reload();
  }
}
