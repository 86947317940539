import { Controller } from "stimulus";
import Cookies from '../utils/cookies';
import ahoy from "../utils/ahoy";
// import ahoy from "ahoy.js";

export default class extends Controller {
  connect() {
    window.addEventListener("message", this.messageHandler.bind(this));
    this.intersectionObserver(this.element.id);
  }
  
  initialize() {
  }

  messageHandler(e) {
    try {
      let data = JSON.parse(e.data);
      if(data.trackingReady) this.ready(data);
    } catch(e) { console.log(`Error: ${e}}`) }
  }

  get customerDonationsController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("mainCustomerDonationsWidget"), "customer-donations")
  }

  trackAction(e) {
    const metaData = e.currentTarget.dataset["metadata"] ? JSON.parse(e.currentTarget.dataset["metadata"]) : {}
    this.trackEvent(e.currentTarget, e.currentTarget.dataset["eventName"], metaData);
  }

  trackEvent(element, eventName, metaData) {
    let data = { ...this.metadata, ...metaData }
    ahoy.track(eventName, data);
  }

  donationAdded(e) {
    const causeMetaData = this.customerDonationsController.retrieveCauseMetaData();    
    const amount = e.currentTarget.dataset["quantity"];
    this.metadata.amount = parseInt(amount);
    this.metadata.cause = causeMetaData
    ahoy.track('added donation to cart', this.metadata);
  }

  intersectionObserver(elementId) {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.99,
    };
    
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.trackEvent(this.element, "widget viewed", {});
          observer.unobserve(entry.target);
        }
      });
    }, options);
  
    let widget = document.getElementById(elementId);
  
    observer.observe(widget);
  }

  setMetadata(data) { 
    console.log(data);
    this.metadata = JSON.parse(this.element.dataset["metadata"]);
  
    this.metadata.page_name = data.name;
    this.metadata.page_url = data.url;
    this.metadata.cart_token = data.cartToken;
    if(data.checkoutToken) this.metadata.checkout_token = data.checkoutToken;
  }
  
  handleVisitorId(visitorId) {
    let existingVisitorId = Cookies.get("virtue_visitor") 
    if(!existingVisitorId || (existingVisitorId != visitorId)) {
      Cookies.set("virtue_visitor", visitorId, (2 * 365 * 24 * 60));
    }
  }

  handleVisitId(visitId) {
    let existingVisitId = Cookies.get("virtue_visit") 
    if(!existingVisitId || (existingVisitId != visitId)) {
      Cookies.set("virtue_visit", visitId, (4 * 60));
    }
  }

  ready(data) {
    this.setMetadata(data.pageData);
    this.handleVisitorId(data.visitorId);
    this.handleVisitId(data.visitId);
    ahoy.configure({
      visitsUrl: `${this.element.dataset.trackUrl}/ahoy/visits`,
      eventsUrl: `${this.element.dataset.trackUrl}/ahoy/events`,
      headers: {
        "Ahoy-Visit": data.visitId,
        "Ahoy-Visitor": data.visitorId
      }
    });
    //Set the visitor id
    ahoy.start();
  }
};
