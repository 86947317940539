import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["option"]

  connected() {
    this.updateOverlays();
  }

  updateOverlays() {
    this.optionTargets.forEach(option => {
      const input = option.querySelector('input');
      const overlay = option.querySelector('.selected-overlay');

      if (input.checked) {
        overlay.classList.remove('d-none');
        overlay.classList.add('d-flex');
      } else {
        overlay.classList.remove('d-flex');
        overlay.classList.add('d-none');
      }
    });
  }
}
