import { Controller } from "stimulus";
import Turbolinks from "turbolinks";
import { getMetaValue } from "../utils/helpers";
import { renderInModal } from "../utils/modals";

export default class extends Controller {
  static targets = ["form", "container", "label", "radioButton", "givingAmount", "productSelect"]

  change_label(e) {
    if(e.currentTarget.value === "percentage"){
      this.labelTarget.innerText = "%";
      this.givingAmountTarget.setAttribute('max', '100');
    } else {
      this.labelTarget.innerText = this.labelTarget.dataset["currency"];
      this.givingAmountTarget.removeAttribute('max');
    }
  }

  submitForm(e) {
    this.formTarget.submit();
  }

  async submitFormAsync() {
    const data = new FormData(this.formTarget);
    const response = await fetch(this.formTarget.action, {
      method: "PUT",
      body: data,
      headers: {
        'X-Requested-With':'XMLHttpRequest',
        "X-CSRF-Token": getMetaValue("csrf-token"),
      }
    })
    const redirect = response.headers.get('Location');
    if (redirect) {
      Turbolinks.visit(redirect);
    } else {
      renderInModal(await response.text())
    }
  }
}
