import { Controller } from 'stimulus'
import { getMetaValue } from "../utils/helpers"

export default class extends Controller {
  static targets = [ "modal", "survey", "appStore", "thankYou" ]

  connect () {
    if(this.modalTarget) this.show();
  }
  show() {
    $(".modal").modal('hide');
    $(this.modalTarget).modal('show');
    fetch('/api/v1/feedback/modal_shown.json ', {
      method: 'POST',
      body:'',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getMetaValue("csrf-token")
      },
      credentials: 'same-origin'
    })
  }
  answer(e) {
    e.preventDefault();
    let val = parseInt(e.currentTarget.value)
    const platformType = e.currentTarget.dataset.platformType;
    fetch('/api/v1/feedback.json ', {
      method: 'POST',
      body: JSON.stringify({nps_score: val}),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getMetaValue("csrf-token")
      },
      credentials: 'same-origin'
    }).then(response => {
      console.log(this.modalTarget.dataset["link"], "<<<link");
      this.surveyTarget.classList.add("d-none")
      if(val < 10 || !this.modalTarget.dataset["link"]) {
        this.thankYouTarget.classList.remove("d-none")
      } else {
        this.appStoreTarget.classList.remove("d-none")
        setTimeout(() => {
          window.location.href = this.modalTarget.dataset["link"]
        }, 1500)
      }
    })
  }
  dismiss(e) {
    document.body.classList.remove("modal-open");
    this.modalTargets[0].removeAttribute("style");
    this.modalTargets[0].classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
  }
}
