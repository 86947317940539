import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["collections"]

  initialize() {
    // this.check_collections();
  }

  connect() {

  }

  toggleCollections(e) {
   if(e.currentTarget.value == "all_products") {
     this.collectionsTarget.classList.add("d-none")
   } else {
    this.collectionsTarget.classList.remove("d-none")
   }
  }
  
}
