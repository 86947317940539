import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "filterable" ]

  initialize() {
  }

  async getParam(e) {
    e.preventDefault();
    const val = e.currentTarget.value;
    window.location.search = `${e.currentTarget.dataset.key}=${val}`;
  }

  filter() {
    let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()
  
    this.filterableTargets.forEach((el, i) => {
      let filterableKey =  el.getAttribute("data-filter-key")
  
      el.classList.toggle("d-none", !filterableKey.includes( lowerCaseFilterTerm ) )
    })
  }
}