import { Controller } from "stimulus"
import { hexToRgba } from "../utils/helpers"

export default class extends Controller {
  static targets = [
    "accentColor",
    "bgColor",
    "border",
    "borderRadius",
    "customMobilePosition",
    "customMobileSelector",
    "customPosition",
    "customSelector",
    "disableCartListener",
    "height",
    "mobileHeight",
    "mobileWidth",
    "rowPadding",
    "shadow",
    "textColor",
    "width",
  ];

  connect() {
    this.root = document.documentElement;
  }

  handleThemeChanged(e) {
    let select = e.currentTarget;
    let theme = JSON.parse(select.options[select.selectedIndex].dataset.theme);
    if (theme["key"] != "custom") {
      this.bgColorTarget.value = theme["background_color"];
      this.handleCssChange(this.bgColorTarget);
      this.textColorTarget.value = theme["font_color"];
      this.handleCssChange(this.textColorTarget);
      this.handleRgbChange(this.textColorTarget.dataset.rgbkey, this.textColorTarget, 0.1);
      this.handleRgbChange(`${this.textColorTarget.dataset.rgbkey}-dark`, this.textColorTarget, 0.2);
      this.handleRgbChange(`${this.textColorTarget.dataset.rgbkey}-darker`, this.textColorTarget, 0.3);
      this.accentColorTarget.value = theme["accent_color"];
      this.handleCssChange(this.accentColorTarget);
      this.handleRgbChange(this.accentColorTarget, 0.05);
      this.borderTarget.value = theme["border"];
      this.handleCssChange(this.borderTarget);
      this.borderRadiusTarget.value = theme["border_radius"];
      this.handleCssChange(this.borderRadiusTarget);
      this.shadowTarget.value = theme["drop_shadow"];
      this.handleShadowUpdate(this.shadowTarget.dataset.csskey,
        this.textColorTarget.value,
        this.shadowTarget.value, 0.25);
      this.rowPaddingTarget.value = theme["row_padding"];
      this.handleCssChange(this.rowPaddingTarget);
    }
  }

  handleCssChange(target) {
    let prefix = '';
    if (target.dataset.cssprefix) prefix = target.dataset.cssprefix;
    let suffix = '';
    if (target.dataset.csssuffix) suffix = target.dataset.csssuffix;
    let val = target.value;
    if (val == "true") val = 1;
    else if (val == "false") val = 0;
    this.root.style.setProperty(`--${target.dataset.csskey}`, `${prefix}${val}${suffix}`);
  }

  handleShadowUpdate(key, fontCol, shadowPx, shadowGrad) {
    this.root.style.setProperty(`--${key}`, `0px 0px ${shadowPx}px ${hexToRgba(fontCol, shadowGrad)}`);
  }

  // handleRgbInputChange() {}
  handleRgbChange(key, target, val) {
    this.root.style.setProperty(`--${key}`, hexToRgba(target.value, val));
  }

}