import { Controller } from 'stimulus'
import { loadScript } from "@paypal/paypal-js";
import { logException } from "../utils/helpers.js"

export default class extends Controller {
  static targets = ["paypalButton"];

  async getBaInitToken() {
    let response = await fetch("/paypal/ba_init_token.json");
    return response.ba_token;
  }

  connect() {
    loadScript({ "client-id": this.paypalClientID, "vault": true, "currency": this.paypalButtonTarget.dataset["currency"] })
      .then((paypal) => {
          // start to use the PayPal JS SDK script
          paypal.Buttons({
            style: {
                layout:   "horizontal",
                 color:   "gold",
                 shape:   "rect",
                 label:   "paypal",
                 tagline: false,
                 height:  55
             },
             createBillingAgreement: function(data, actions) {
                //Get the billing agreement token
                return fetch("/paypal/ba_init_token.json").then((response) => response.json()).then((data) => data.ba_token);
             },
             onApprove: function (data, actions) {
               return fetch("/paypal/save_ba.json", {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "X-CSRF-Token": document.head.querySelector("meta[name=csrf-token]").content
                },
                body: JSON.stringify({ba_id: data.billingToken})
              }).then((response) => response.json()).then((data) => {
                window.location.href = "/";
              }).catch(error => {
                logException(error);
                let existingAlert = document.querySelector(".card-block .alert")
                if(existingAlert) existingAlert.remove();
                let alert = document.createElement("div");
                alert.setAttribute("class", "alert alert-danger");
                alert.innerHTML = "Oops! It looks like there was an error linking your Paypal account with Pledger. Please try again or contact support if you still have issues.";
                document.querySelector(".card-block").prepend(alert);
            });
             },
             onCancel: function () {
                let existingAlert = document.querySelector(".card-block .alert")
                if(existingAlert) existingAlert.remove();
                // Display a notification telling the user they need to setup paypal to accept donations
                let alert = document.createElement("div");
                alert.setAttribute("class", "alert alert-danger");
                alert.innerHTML = "Oops! It looks like you cancelled your Paypal account linking with Pledger. Pledger requires a Paypal account to process donations from you to your chosen causes.";
                document.querySelector(".card-block").prepend(alert);
             },
             onError: function (err) {
              logException(err);
                let existingAlert = document.querySelector(".card-block .alert")
                if(existingAlert) existingAlert.remove();
                // Notify the user to try again or contact support if problems persist
                let alert = document.createElement("div");
                alert.setAttribute("class", "alert alert-danger");
                alert.innerHTML = "Oops! It looks like there was an error linking your Paypal account with Pledger. Please try again or contact support if you still have issues.";
                document.querySelector(".card-block").prepend(alert);
             }
         }).render(this.paypalButtonTarget);

      })
      .catch((err) => {
        logException(err);
        console.error("failed to load the PayPal JS SDK script", err);
      });
  }

  get paypalClientID() {
    return document.head.querySelector("meta[name=paypal_client]").content
  }


}
