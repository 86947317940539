import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "modal"]

  connect () {
    if(this.modalTarget) this.show();
  }
  show() {
    $(".modal").modal('hide');
    $(this.modalTarget).modal('show');
  }
  dismiss(e) {
    document.body.classList.remove("modal-open");
    this.modalTargets[0].removeAttribute("style");
    this.modalTargets[0].classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
  }
}
