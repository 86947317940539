import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['switch', 'switchLabel']

  connect() {
    $('#goLiveModal').on('hide.bs.modal', (event) => {
      this.switchTarget.checked = false
      this.toggle()
    })
    $('[data-toggle="tooltip"]').tooltip()
  }

  toggle() {
    if (this.switchTarget.checked === true) {
      $('#goLiveModal').modal('show')
      this.switchLabelTarget.textContent = "Deactivate"
    } else {
      this.switchLabelTarget.textContent = "Activate"
    }
    if($('#goLiveModal').length <= 0) {
      fetch("/shop-activation-toggle")
        .then(response => response.json())
        .then((json) => {
          //Check for redirect
          if(json.redirect_url.length > 0) {
            window.location.href = json.redirect_url;
          }
      });
    }
  }
}
