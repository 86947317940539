import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "form", "successOptInMessage", "input" ];

  connect() {
  }
  
  initialize() {
  }

  submit(e) {
    // stop user from pressing the switch
    // this.inputTarget.setAttribute("disabled", true);
    console.log(this.inputTarget.value);
    Rails.fire(this.formTarget, 'submit');
  }

  displayProcessing(e) {
    // console.log('displayPorcessing')
  }

  onPostSuccess(e) {
    this.formTarget.classList.add("d-none");
    this.successOptInMessageTarget.classList.add("d-block");
    this.successOptInMessageTarget.classList.remove("d-none");
  }

  onPostError(e) {
    // console.log('onPostError')
  }
};
