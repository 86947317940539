import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "pagination", "causeIconEntries", "causeIconInput", "uploadLogoSection", "iconsSection", "causeIcon", "searchInput" ]

  connect () {
  }

  toggleIconLogo(e) {
    if(e.currentTarget.value == "icon") {
      this.iconsSectionTarget.classList.remove("d-none");
      this.uploadLogoSectionTarget.classList.add("d-none");
    } else {
      this.iconsSectionTarget.classList.add("d-none");
      this.uploadLogoSectionTarget.classList.remove("d-none");
    }
  }

  selectCauseIcon(e) {
    this.causeIconTargets.filter(b => b.classList.contains("chosen-cause-icon")).forEach((i) => {
      i.classList.remove("chosen-cause-icon");
    });
    e.currentTarget.classList.add("chosen-cause-icon");
    this.causeIconInputTarget.value = e.currentTarget.dataset["causeIconId"];
  }

  searchPress(e) {
    if(e.keyCode == 13){
      e.preventDefault();
      this.doSearch(e);
    }
  }
  doSearch(e) {
    e.preventDefault();
    let searchQuery = e.currentTarget.parentElement.parentElement.querySelector("input").value;
    const data = new URLSearchParams(new FormData(this.formTarget));
    fetch(`/cause_icons?icons_search=${this.searchInputTarget.value}`, {
      method: 'get',
      headers: {
        'X-Requested-With':'XMLHttpRequest'
      }
    })
    .then(response => response.json())
    .then((json) => {
      //Show the results?
      this.causeIconEntriesTarget.innerHTML = json.entries;
      this.paginationTarget.innerHTML = json.pagination;

    });
  }
}