import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "defaultLanguage", "form" ]

  connect () {
  }

  selectDefault(e) {
    this.defaultLanguageTarget.value = e.currentTarget.dataset["language"];
    this.formTarget.submit();
  }
}
