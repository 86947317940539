import { Controller } from "stimulus"
import noUiSlider from "nouislider";

export default class extends Controller {
  static targets = ["slider", "input", "display"];

  connect() {
    noUiSlider.create(this.sliderTarget, {
      start: 10,
      step: 1,
      connect: "lower",
      range: {
          'min': 0,
          'max': 50
      }
    });
  }
  get inputValue() {
    if(this.inputTarget.value) return parseInt(this.inputTarget.value);
    else return 0;
  }
  get minValue() {
    if(this.element.dataset.min) return parseInt(this.element.dataset.min);
    else return 0;
  }
  get maxValue() {
    if(this.element.dataset.max) return parseInt(this.element.dataset.max);
    else return -1;
  }
}