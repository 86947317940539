import { Controller } from "stimulus"
import moment from 'moment';
export default class extends Controller {
  static targets = [ "date", "time" ]
  initialize() {
    moment.locale(navigator.userLanguage || navigator.language);
  }
  connect() {
    if(this.dateTarget) {
      $(this.dateTarget).datetimepicker({ format: 'L', minDate: this.element.dataset["minDate"] ? moment() : undefined });
      
    }
    if(this.timeTarget) {
      $(this.timeTarget).datetimepicker({ format: 'HH:mm' });
      
    }
  }
}
