import { track_with_groups } from "mixpanel-browser";
import { Controller } from "stimulus";
import { getMetaValue } from "../utils/helpers";

export default class extends Controller {
  static targets = [
    "addon",
    "addonModal",
    "featuresModal",
    "chosenPlanName",
    "payPeriod",
    "planTwoPeriod",
    "planThreePeriod",
    "addonPeriod",
    "addonPeriodModal",
    "addonSetting",
    "planContainer",
    "btnContinueWithAddon",
    "btnChangePlan",
    "addCustomerGiving",
    "planContainer",
    "cards",
  ];

  connect() {
    // this.setPayPeriod();
    // this.checkAddonAndPayPeriod();
  }

  featuresCheck() {
    return fetch("/subscription/features_check", {
      method: "POST",
      body: JSON.stringify({
        chosen_plan_id: this.pricingPlan,
        addon_plan_id: this.addon,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((json) => {
        // set chonsenPlan name
        const chosenPlanName = json.plan_name;
        const chosenPlanLevel = json.plan_level;
        this.chosenPlanNameTarget.textContent = chosenPlanName;
        let objectLength = Object.keys(json.features_missing).length;
        // Remove all recently added children to prevent duplications
        while (this.featuresModalTarget.firstChild) {
          this.featuresModalTarget.removeChild(
            this.featuresModalTarget.firstChild,
          );
        }

        // Set display of customer giving add on
        if (!this.addonTarget.checked && chosenPlanLevel === 1) {
          this.addCustomerGivingTarget.classList.remove("d-none");
        } else {
          this.addCustomerGivingTarget.classList.add("d-none");
        }

        if (objectLength > 0) {
          // Iterate throught the features missing values and list them in the modal body
          for (var key in json.features_missing) {
            let element = `<li style="display: flex; align-items: center;"><span class="fa fa-times-circle" style="color: red; margin-right: 8px;font-size: 26px;"></span><p style="color: black; font-size: 18px; margin: 0;">${json.features_missing[key]}</p></li>`;
            let child = document.createElement("li");
            child.innerHTML = element;
            child = child.firstChild;
            this.featuresModalTarget.appendChild(child);
          }
          this.modalController(this.element).showManual(0);
          return true;
        } else {
          return false;
        }
      });
  }

  selectPlan(e) {
    this.pricingPlan = e.currentTarget.dataset["pricingPlan"];

    this.processAddon(e);

    this.qty = e.currentTarget.dataset["quantity"];
    this.activate = e.currentTarget.dataset["activate"];
    this.featuresCheck().then((result) => {
      if (result == true) return;
      if (this.element.dataset.paymentType == "shopify") {
        this.selectShopifyPlan(e);
      } else {
        this.selectStripePlan(
          this.pricingPlan,
          this.qty,
          this.activate,
          this.addon,
        );
      }
    });

    e.returnValue = false;
  }
  
  processAddon(e) {
    let shouldProcessAddon = e.currentTarget.dataset["allowAddon"] || false;
    if (!shouldProcessAddon) return;

    const addonIdx = parseInt(e.currentTarget.dataset["addonIndex"]) || 0;
    const addonTarget = this.addonTargets[addonIdx];
    if (!addonTarget) {
      console.error("No valid add-on found at index: "+addonIdx);
      return;
    }

    this.addon = addonTarget.checked
      ? addonTarget.dataset["pricingPlan"]
      : null;

    if (e.currentTarget.dataset["addonPricingPlan"])
      this.addon = e.currentTarget.dataset["addonPricingPlan"];

    if (!this.payPeriodTarget?.checked) return;

    if (e.currentTarget.dataset["annualPricingPlan"])
      this.pricingPlan = e.currentTarget.dataset["annualPricingPlan"];

    this.addon =
      e.currentTarget.dataset["allowAddon"] &&
      this.addonTarget.checked
        ? this.addonTarget.dataset["annualPricingPlan"]
        : null;

    if (e.currentTarget.dataset["annualAddonPricingPlan"])
      this.addon = e.currentTarget.dataset["annualAddonPricingPlan"];
  }

  selectShopifyPlan(e) {
    let btnPlan = e.currentTarget;
    //Show loading
    fetch(`/payments/shopify/new_subscription.json`, {
      method: "POST",
      body: JSON.stringify({
        plan: e.currentTarget.dataset["pricingPlan"],
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((json) => {
        // Redirect after response
        if (json.confirmationUrl) {
          window.location.href = json.confirmationUrl;
        } else {
          this.loadController(btnPlan).stopProcessing();
          //Display an error
          this.displayError();
        }
      })
      .catch((error) => {
        this.loadController(btnPlan).stopProcessing();
        this.displayError();
      });
  }

  displayError() {
    //Stop loading
    let existingAlert = document.querySelector(".text-center.mt-4 .alert");
    if (existingAlert) existingAlert.remove();
    let alert = document.createElement("div");
    alert.setAttribute("class", "alert alert-danger");
    alert.innerHTML =
      "Oops! It looks like there was an error subscribing to your plan. Please try again or contact support if you still have issues.";
    document.querySelector(".text-center.mt-4").prepend(alert);
  }

  modalAddonToggled(e) {
    if (this.addonModalTarget.checked) {
      this.btnChangePlanTarget.classList.add("d-none");
      this.btnContinueWithAddonTarget.classList.remove("d-none");
    } else {
      this.btnChangePlanTarget.classList.remove("d-none");
      this.btnContinueWithAddonTarget.classList.add("d-none");
    }
  }

  continueWithAddon(e) {
    e.preventDefault();
    console.log(this.addonModalTarget.dataset["pricingPlan"]);
    this.addon = this.addonModalTarget.checked
      ? this.addonModalTarget.dataset["pricingPlan"]
      : null;
    if (this.payPeriodTarget.checked) {
      this.addon = this.addonModalTarget.checked
        ? this.addonModalTarget.dataset["annualPricingPlan"]
        : null;
    }
    this.continueWithPlanSelection(e);
  }

  continueWithPlanSelection(e) {
    e.preventDefault();
    if (this.element.dataset["mode"] == "new") {
      this.selectStripePlan(
        this.pricingPlan,
        this.qty,
        this.activate,
        this.addon,
      );
    } else if (this.element.dataset["mode"] == "change") {
      this.changeStripePlan(
        this.pricingPlan,
        this.qty,
        this.activate,
        this.addon,
      );
    }
  }

  unsupportedAddon(e) {
    e.preventDefault();
    alert("You cannot select this addon with your current plan.");
  }

  chooseAddon(e) {
    if (
      confirm(
        e.currentTarget.checked
          ? "Proceed with adding customer add on?"
          : "Proceed with removing the customer add on?",
      )
    ) {
      this.addon = this.payPeriodTarget.checked
        ? this.addonTarget.dataset["annualPricingPlan"]
        : this.addonTarget.dataset["pricingPlan"];
      let addOnPlan =
        e.currentTarget.dataset["annualPricingPlan"] ||
        e.currentTarget.dataset["pricingPlan"];
      window.location.href = `${this.element.dataset["addonPath"]}?addon_price_id=${addOnPlan}`;
    } else {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  selectStripePlan(pricingPlan, quantity, activate, addonPlan = null) {
    let selectPlanUrl = `/stripe/subscription/new?price_id=${pricingPlan}`;
    if (quantity) selectPlanUrl += `&quantity=${quantity}`;
    if (activate) selectPlanUrl += `&activate=${activate}`;
    if (addonPlan) selectPlanUrl += `&addon_price_id=${addonPlan}`;
    fetch(selectPlanUrl)
      .then((response) => response.json())
      .then((json) => {
        if (json.session) {
          Stripe(this.data.get("apiKey"))
            .redirectToCheckout({
              sessionId: json.session.id,
            })
            .then((result) => {
              console.log("Checkout response");
              console.log(result);
            });
        } else {
          window.location.href = json.redirect;
        }
      });
  }

  changePlan(e) {
    this.addon =
      e.currentTarget.dataset["allowAddon"] && this.addonTarget.checked
        ? this.addonTarget.dataset["pricingPlan"]
        : null;
    if (e.currentTarget.dataset["addonPricingPlan"])
      this.addon = e.currentTarget.dataset["addonPricingPlan"];
    this.pricingPlan = e.currentTarget.dataset["pricingPlan"];
    if (this.payPeriodTarget.checked) {
      // Check to make sure user hasn't selected plan 1.
      if (e.currentTarget.dataset["annualPricingPlan"])
        this.pricingPlan = e.currentTarget.dataset["annualPricingPlan"];

      this.addon =
        e.currentTarget.dataset["allowAddon"] && this.addonTarget.checked
          ? this.addonTarget.dataset["annualPricingPlan"]
          : null;
      if (e.currentTarget.dataset["annualAddonPricingPlan"])
        this.addon = e.currentTarget.dataset["annualAddonPricingPlan"];
    }

    this.qty = e.currentTarget.dataset["quantity"];
    this.activate = e.currentTarget.dataset["activate"];
    // Features check
    this.featuresCheck().then((result) => {
      if (!result)
        this.changeStripePlan(
          this.pricingPlan,
          this.qty,
          this.activate,
          this.addon,
        );
    });
  }

  loadController(el) {
    return this.application.getControllerForElementAndIdentifier(el, "load");
  }

  changeStripePlan(pricingPlan, quantity, activate, addonPlan = null) {
    let path = `/subscription/change?price_id=${pricingPlan}`;
    if (addonPlan !== null) path = `${path}&addon_price_id=${addonPlan}`;
    window.location.replace(location.protocol + "//" + location.host + path);
  }

  setPayPeriod() {
    this.payPeriodCheck();
    if (this.cardsTarget.dataset["change"] == "true") {
      let selectedPlan = this.planContainerTargets.find(
        (plan) => plan.dataset["selectedPlan"] == "true",
      );
      selectedPlan.querySelector(".plan-header").textContent =
        "Your current plan";
    }
  }

  togglePayPeriod() {
    this.payPeriodCheck();
    if (this.cardsTarget.dataset["change"] == "true") {
      let selectedPlan = this.planContainerTargets.find(
        (plan) => plan.dataset["selectedPlan"] == "true",
      );
      if (!selectedPlan.dataset["plan-1"]) {
        let header = selectedPlan.querySelector(".plan-header");
        let background = selectedPlan.querySelector(".plan-background");
        let buttonContainer = selectedPlan.querySelector(".button-container");
        let buttonContainerSeleceted = selectedPlan.querySelector(
          ".button-container-selected",
        );
        let ribbon = selectedPlan.querySelector(".ribbon");

        header.textContent =
          header.textContent === "Your current plan"
            ? "First 10 orders with giving FREE"
            : "Your current plan";
        background.classList.toggle("rounded-top");
        background.classList.toggle("selected-plan-background-color");
        selectedPlan.classList.toggle("selected-plan-border");
        buttonContainer.classList.toggle("display-none");
        buttonContainerSeleceted.classList.toggle("display-none");
        ribbon && ribbon.classList.toggle("display-none");
      }
    }
  }

  payPeriodCheck() {
    if (this.payPeriodTarget.checked) {
      let planTwoPrice = this.planTwoPeriodTarget.dataset["anPrice"];
      this.planTwoPeriodTarget.textContent = `${planTwoPrice} p/m (paid annually)`;

      let planThreePrice = this.planThreePeriodTarget.dataset["anPrice"];
      this.planThreePeriodTarget.textContent = `${planThreePrice} p/m (paid annually)`;
    } else {
      let addOnPrice = this.addonPeriodTarget.dataset["price"];
      this.addonPeriodModalTarget.textContent = `Add Customer Giving as an add on for ${addOnPrice} p/m`;
      this.addonPeriodTarget.textContent = `Add Customer Giving as an add on for ${addOnPrice} p/m`;

      let planTwoPrice = this.planTwoPeriodTarget.dataset["price"];
      this.planTwoPeriodTarget.textContent = `${planTwoPrice}/month`;

      let planThreePrice = this.planThreePeriodTarget.dataset["price"];
      this.planThreePeriodTarget.textContent = `${planThreePrice}/month`;
    }
  }

  checkAddonAndPayPeriod() {
    if (this.addonTarget.dataset["addonSubscribed"] === "true") {
      this.addonTarget.checked = true;
    }
    if (this.payPeriodTarget.dataset["payQuarterly"] === "true") {
      this.payPeriodTarget.checked = true;
    }
  }

  loadController(el) {
    return this.application.getControllerForElementAndIdentifier(el, "load");
  }
  modalController(el) {
    return this.application.getControllerForElementAndIdentifier(el, "modal");
  }
}
