import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["entries", "pagination"]
    connect() {


  }
  scroll(e) {
    let scroller = document.getElementById('select_products_scroll');
      let height = scroller.clientHeight;
      let scrollHeight = scroller.scrollHeight - height;
      let scrollTop = scroller.scrollTop;
      let percent = Math.floor(scrollTop / scrollHeight * 100);
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return;  }
    let url = next_page.href
    var body = document.body,
      html = document.documentElement
    if (percent >= 95) {
      this.paginationTarget.innerHTML = "";
      let result = this.loadMore(url);
      let selected_collections = document.getElementById("selected_products").children;
      let selected_ids = [];
      let i=0;
      let all_collections = document.getElementsByClassName("sellect-collection-popup");
      for (i = 0; i < selected_collections.length; i++) {
          selected_ids.push(selected_collections[i].id);
        }
    for (i = 0; i < all_collections.length; i++) {
      if(selected_ids.includes(all_collections[i].value)) {
        all_collections[i].checked = true;
      }
      }
      if (result === undefined) {
        return '';
      }

      return result;
    }
  }

  loadMore(url) {
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.paginationTarget.innerHTML = data.pagination ;

      document.getElementById('count_of_all_collections').textContent = `Showing ${document.getElementsByClassName("sellect-collection-popup").length} collections`

         }
      }
    )
  }
}
