import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "viewAll",
    "entries",
    "pagination",
    "filterButton",
    "form",
    "input",
  ];

  connect() {
    this.setViewAllHighlight();
  }

  async filter(e) {
    this.toggleHighlight(e.currentTarget);

    if (this.unsavedChangesController.isFormChanged()) {
      const data = new URLSearchParams(new FormData(this.formTarget));
      await fetch(this.formTarget.getAttribute("action"), {
        method: "post",
        body: data,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      this.unsavedChangesController.allowFormSubmission();
    } else {
      this.loadMore(this.url.replace("?", ".json?"));
    }

    this.setViewAllHighlight();
    this.updateWindowLocation();
  }

  get selectedThemes() {
    return this.filterButtonTargets.filter((b) =>
      b.classList.contains("theme-tag-highlighted")
    );
  }

  get infiniteScrollController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("infinite-scroll-causes"),
      "infinite-scroll"
    );
  }

  get unsavedChangesController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("unsaved-changes"),
      "unsaved-changes"
    );
  }

  get url() {
    let newUrl = `${window.location.pathname}`;

    if (this.selectedThemes.length > 0) {
      newUrl +=
        "?theme_ids[]=" +
        this.selectedThemes.map((btn) => btn.id).join("&theme_ids[]=");
    }

    return newUrl;
  }

  highlight(target) {
    target.classList.add("theme-tag-highlighted");
  }
  unhighlight(target) {
    target.classList.remove("theme-tag-highlighted");
  }
  toggleHighlight(target) {
    target.classList.toggle("theme-tag-highlighted");
  }

  setViewAllHighlight() {
    if (this.selectedThemes.length > 0) {
      this.unhighlight(this.viewAllTarget);
    } else {
      this.highlight(this.viewAllTarget);
    }
  }

  loadMore(url) {
    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (data) => {
        this.entriesTarget.innerHTML = data.entries;
        this.paginationTarget.innerHTML = data.pagination;
        let infScroll = this.infiniteScrollController;
        if (infScroll && infScroll != null) infScroll.reset();
      },
    });
  }

  updateWindowLocation() {
    window.history.pushState(null, null, this.url);
  }
}
