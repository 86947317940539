import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["control", "controlContainer"]

  initialize() {
    this.disableValue = this.element.dataset["disablevalue"];
    this.enableValue = this.element.dataset["enablevalue"];
  }

  connect() {
  }

  handleControls(e) {
    let disabled = e.currentTarget.value == this.disableValue;
    this.controlContainerTargets.forEach((container) => {
      container.style.display = disabled ? "none" : "flex";
    });
    this.controlTargets.forEach((target) => {
      target.disabled = disabled;
      if(!target.dataset["noClear"] && target.nodeName.toLowerCase() == "select") {
        target.selectedIndex = -1;
      } else if(!target.dataset["noClear"]) {
        target.value = "";
      }
    });
  }
}