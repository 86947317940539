var delegate,
    slice = [].slice;

delegate = function(eventName, arg) {
    var handler, parameters;
    parameters = arg.parameters;
    handler = function() {
        var args, data, delegatedEvent, i, index, len, name;
        args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
        data = {};
        for (index = i = 0, len = parameters.length; i < len; index = ++i) {
            name = parameters[index];
            data[name] = args[index];
        }
        delegatedEvent = new CustomEvent("jquery:" + eventName, {
            bubbles: true,
            cancelable: true,
            detail: data
        });
        return data.event.target.dispatchEvent(delegatedEvent);
    };
    return $(document).on(eventName, handler);
};

delegate('colorpickerChange', {parameters: ['event']})
delegate('colorpickerUpdate', {parameters: ['event']})
delegate('hidden.bs.dropdown', {parameters: ['event']})
delegate('change', {parameters: ['event']})
delegate('hide.bs.dropdown', {parameters: ['event']})
delegate('apply.daterangepicker', {parameters: ['event', 'picker']})
delegate('hide.bs.modal', {parameters: ['event']})
delegate('hidden.bs.modal', {parameters: ['event']})

