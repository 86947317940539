import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["fixedOptions", "roundUp"];
  connect() {
   
  }

  toggleSettings(e) {
    if(e.currentTarget.value == "fixed_options") {
      this.roundUpTarget.classList.add("d-none");
      this.fixedOptionsTarget.classList.remove("d-none");
    } else {
      this.roundUpTarget.classList.remove("d-none");
      this.fixedOptionsTarget.classList.add("d-none");
    }
  }
}