import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  connect() {
    if(this.hasButtonTarget) {
        this.defaultButtonText = this.buttonTarget.textContent;
        var loader = document.createElement("span")
        loader.classList.add("spinner-border", "spinner-border-sm")
        this.loader = loader;
    }
  }


  displayProcessing(e) {
    e.preventDefault();
    if (this.buttonTarget) {
      this.buttonTarget.style.pointerEvents = 'none';
      this.buttonTarget.innerHTML = this.loader.outerHTML;
    } else {
      console.log("no button target")
    }
  }
  stopProcessing() {
    console.log("stop processing")
    this.buttonTarget.style.pointerEvents = 'auto';
    this.buttonTarget.removeAttribute("disabled");
    this.buttonTarget.textContent = this.defaultButtonText;
  }
}