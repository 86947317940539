export const TRANSLATIONS = {
  en: {
    donation: "%{currency}%{amount} donation",
    organization: "to support %{organizationName} has been added to your cart!",
    earned: "You've earned ",
    support: " to support a cause."
  },
  ar: {
    donation: "%{currency}%{amount} تبرع",
    organization: "تمت إضافة %{organizationName} إلى سلة التسوق الخاصة بك!",
    earned: "لقد حصلت على ",
    support: " لدعم قضية."
  },
  cs: {
    donation: "Dar ve výši %{currency}%{amount}",
    organization: "pro %{organizationName} byl přidán do vašeho košíku!",
    earned: "Získal(a) jste ",
    support: " pro podporu příčiny."
  },
  da: {
    donation: "%{currency}%{amount} donation",
    organization: "til %{organizationName} er blevet tilføjet til din indkøbskurv!",
    earned: "Du har tjent ",
    support: " til at støtte en sag."
  },
  de: {
    donation: "%{currency}%{amount} Spende",
    organization: "für %{organizationName} wurde Ihrem Warenkorb hinzugefügt!",
    earned: "Du hast verdient ",
    support: " um eine Sache zu unterstützen."
  },
  es: {
    donation: "Donación de %{currency}%{amount}",
    organization: "a %{organizationName} se ha añadido a tu carrito!",
    earned: "Has ganado ",
    support: " para apoyar una causa."
  },
  fi: {
    donation: "%{currency}%{amount} lahjoitus",
    organization: "%{organizationName} on lisätty ostoskoriisi!",
    earned: "Olet ansainnut ",
    support: " tukeaksesi hyvää tarkoitusta."
  },
  fr: {
    donation: "Don de %{currency}%{amount}",
    organization: "pour %{organizationName} a été ajouté à votre panier!",
    earned: "Vous avez gagné ",
    support: " pour soutenir une cause."
  },
  it: {
    donation: "Donazione di %{currency}%{amount}",
    organization: "a %{organizationName} è stato aggiunto al tuo carrello!",
    earned: "Hai guadagnato ",
    support: " per sostenere una causa."
  },
  nl: {
    donation: "Donatie van %{currency}%{amount}",
    organization: "voor %{organizationName} is toegevoegd aan je winkelwagen!",
    earned: "Je hebt verdiend ",
    support: " om een doel te ondersteunen."
  },
  pl: {
    donation: "Darowizna w wysokości %{currency}%{amount}",
    organization: "dla %{organizationName} został dodany do koszyka!",
    earned: "Zarobiłeś ",
    support: " na wsparcie sprawy."
  },
  "pt-BR": {
    donation: "Doação de %{currency}%{amount}",
    organization: "para %{organizationName} foi adicionado ao seu carrinho!",
    earned: "Você ganhou ",
    support: " para apoiar uma causa."
  },
  pt: {
    donation: "Doação de %{currency}%{amount}",
    organization: "para %{organizationName} foi adicionado ao seu carrinho!",
    earned: "Você ganhou ",
    support: " para apoiar uma causa."
  },
  sv: {
    donation: "%{currency}%{amount} donation",
    organization: "för %{organizationName} har lagts till i din kundvagn!",
    earned: "Du har tjänat ",
    support: " för att stödja en sak."
  },
  vi: {
    donation: "%{currency}%{amount} quyên góp",
    organization: "đã được thêm vào giỏ hàng của bạn: %{organizationName}!",
    earned: "Bạn đã kiếm được ",
    support: " để ủng hộ một lý do."
  }
}
